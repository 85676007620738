import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Footer from '../layout/Footer';
import UserContext from '../../context/user/userContext';
import useWindowDimensions from '../hooks/getWindowDimensions';


const Settings = () => {
    
    // Define components state hooks.
    const [recEmails, setRecEmails]                             = useState(true);
    const [showName, setShowName]                               = useState(true);
    const [showTags, setShowTags]                               = useState(true);
    const [showPin, setShowPin]                                 = useState(true); 
    const [showSelector, setShowSelector]                       = useState(false);
    const [inpStatusMsg, setInpStatusMsg]                       = useState('');
    const [newPassword, setNewPassword]                         = useState('');
    const [newConfirm, setNewConfirm]                           = useState('');
    const [selectorOption, setSelectorOption]                   = useState({ tag: 'Select', UNID: 0 });
    const [userTagsArr, setUserTagsArr]                         = useState([]);
    const [examTagsArr, setExamTagsArr]                         = useState([]);
    const [newPasswordValid, setNewPasswordValid]               = useState(false);
    const [newConfirmValid, setNewConfirmValid]                 = useState(false);
    const [newPasswordVisible, setNewPasswordVisible]           = useState(false);
    const [newConfirmVisible, setNewConfirmVisible]             = useState(false);
    
    // Reference hooks.
    const history                   = useHistory();
    
    // Get window dimensions.
    const { winWidth }              = useWindowDimensions();
    
    // Reference context apis.
    const userContext               = useContext(UserContext);
    
    // Destructure contexts.
    const { toggleMobileNav, 
            user, 
            updateUserData, 
            errorStatus, 
            errorTag, 
            errorChkbox, 
            errorPassword, 
            isStatusFetching, 
            isTagFetching, 
            isPasswordFetching }    = userContext;
    
    
    
    /*
     * Component methods.
     */
    
    const onChangeValidate = (event, type, validate, maxValue, methods) => {
        
        const tgt       = event.target;
        const pwdRegex  = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
        
        // If validation requested validate value, this will
        // enable/disable submit button.
        if ( validate ) {
            
            switch (type) {
                case 'password':
                    methods.validate(tgt.value !== '' && pwdRegex.test(tgt.value) ? true : false);
                    methods.update(tgt.value);
                    break;
                case 'compare':
                    methods.validate(tgt.value !== '' && tgt.value === methods.compareTo ? true : false);
                    methods.update(tgt.value);
                    break;
                default:
                    methods.validate(tgt.value !== '' ? false : true);
                    methods.update(tgt.value);
            }
            
        }
        
        // No validation requested, update value.
        else methods.update(tgt.value);
 
    }
    
    const onClickExtUrl = url => {
        
        window.open(url);
        
    }
    
    const onChangeCheckbox = (event, state) => {
        
        const checked = event.target.checked;
        
        // Send 'receive emails' update to server.
        if ( state.updateType === 11 ) {
            
            updateUserData({ 
                updatetype: state.updateType,
                permissiondigest: checked ? 1 : 0
            }, 'email');
            
        }
        
        // Else send bitwise checkbox updates to server.
        else {
            
            updateUserData({ 
                updatetype: state.updateType,
                permissions: user.permissions ^ state.setbit
            }, 'chkbox');
            
        }
        
    }
    
    const onClickUpdateStatusMsg = () => {
        
        // PUT new status message onto server.
        updateUserData({ 
            updatetype: 1,
            status: inpStatusMsg
        }, 'status');
        
    }
    
    const onClickUpdatePassword = () => {
        
        // PUT new status message onto server.
        updateUserData({ 
            updatetype: 2,
            newpassword: newPassword
        }, 'password');
        
    }
    
    const onClickToggleSelector = () => {
        
        setShowSelector(!showSelector);
        
    }
    
    const onClickSelectorOption = ({ tag, UNID }) => {
        
        // Call useState method to update user tag aray.
        setSelectorOption({ tag, UNID });
        
        // Hide the selector drop down.
        onClickToggleSelector();
        
    }
    
    const onClickAddTag = () => {

        if ( selectorOption.tag === 'Select' ) return false;
        else {
            
            let chosenItemArr;
            
            // Splice/Remove element from SELECT TAGS array.
            examTagsArr.forEach((item, idx) => {
                
                if ( item.tag === selectorOption.tag ) chosenItemArr = examTagsArr.splice(idx, 1);
                
            });
            
            // When adding a new tag we need to pass 0 as the UNID & then use the tag's real UNID
            // as the value for a new property called 'tagLookupUNID'
            const tmpArr = [ ...userTagsArr, { UNID: 0, taglookupUNID: chosenItemArr[0].UNID } ];
            
            // PUT user tags onto server.
            updateUserData({ 
                updatetype: 12,
                usertags: tmpArr
            }, 'tag');
        
            // Reset selector to show Select label.
            setSelectorOption({ tag: 'Select', UNID: 0 });
            
        }

    }
    
    const onClickRemoveTag = option => {
            
        // Splice element from USER TAGS array.
        userTagsArr.forEach((item, idx) => {
            
            if ( item.tag === option.tag ) userTagsArr.splice(idx, 1);
            
        });

        const tmpArr = [ ...userTagsArr ];
        
        // PUT user tags onto server.
        updateUserData({ 
            updatetype: 12,
            usertags: tmpArr
        }, 'tagRemove');
        
    }
    
    const onClickNavigateTo = path => {
        
        // Navigate to the Settings page using history push.
        history.push('/' + path);
        
    }
    
    const onClickAppPage = event => {
        
        const tgt = event.target;
        
        // If the drop down selector wasn't the target then hide it.
        if ( tgt.className !== 'btn-selector' ) setShowSelector(false);
        
        // Hide the mobile nav, even if not open.
        toggleMobileNav(false);
        
    }
    
    const getUserName = () => {
        
        const userFN = user.FN;
        const userSN = user.SN;
        
        if ( userFN !== '' && userSN !== '' ) return userFN + ' ' + userSN + '\'s ';
        else if ( userFN !== '' && userSN === '' ) return userFN + '\'s ';
        else if ( userFN === '' && userSN !== '' ) return userSN + '\'s ';
        else return '';
        
    }
    
    const removeUsersTags = (examArr) => {

        const uTagsIdArr = user.usertags.map(item => item.tagLookupUNID);

        return examArr.filter(item => !uTagsIdArr.includes(item.UNID));

    }
    
    const addTagNames = (userExamArr) => {
        
        let tmpArr = [];
        
        userExamArr.forEach(item => {
            
            const tagMatch = user.tags.filter( tag => tag.UNID === item.tagLookupUNID );
            
            tmpArr.push({ ...item, tag: tagMatch[0].tag });
            
        });
        
        return tmpArr;
        
    }
    
    const applyServerDataToView = (userData) => {
        
        // Remove any tags that the user already has then populate exam tags array.
        const tmpArr = removeUsersTags([ ...userData.tags ]);
        setExamTagsArr([ selectorOption, ...tmpArr ]);
        
        // Add tag name to item then populate users tags array.
        setUserTagsArr(addTagNames(userData.usertags));
        
        // Set state of checkboxes from server data flags.
        setRecEmails(userData.permissiondigest === 1 ? true : false);
        setShowName(userData.permissions & 2 ? true : false);
        setShowTags(userData.permissions & 256 ? true : false);
        setShowPin(userData.permissions & 128 ? true : false);
        
        // Set status message from server
        if ( userData.status === null ) setInpStatusMsg('');
        else setInpStatusMsg(userData.status);
        
        // Clear password fields.
        setNewPassword('');
        setNewConfirm('');
        setNewPasswordVisible(false);
        setNewConfirmVisible(false);
        
    }
    
    const togglePwdVisibility = state => state.method(!state.value);
    
    
    
    
    /*
     * Component hooks.
     */
    
    useEffect(() => {

        applyServerDataToView(user);
        
    // The eslint comment below removes missing dependency warning from console.
    // eslint-disable-next-line
    }, [user]);
    
    
    
    
    /*
     * Component view.
     */
    return (
        <div className="pages settings" onClick={event => onClickAppPage(event)}>
            <div className="fixed-width flex-fill-space pad-top-40">
                    
                <div className="flex-row-dbl settings-header"> 
                    <h2>{getUserName()}Public Profile Settings</h2>
                    <div className="chkbox">
                        <label htmlFor="remember-me">
                            <input type="checkbox" id="remember-me" checked={recEmails} onChange={event => onChangeCheckbox(event, { method: setRecEmails, updateType: 11 })} />
                            <span className="tick"></span>
                            <span className="label">Receive your weekly Learning Community emails</span>
                        </label>
                    </div>
                </div>
                
                <div className="form-block">
                    <div className="form-element-wrapper mgn-btm-0">
                        <label htmlFor="status-msg">Update your public status message</label>
                        <p>This will appear on your Discover profile.</p>
                        <div className="inp-btn-wrapper">
                            <input type="text" 
                                   id="status-msg" 
                                   maxLength="72"
                                   value={inpStatusMsg}  
                                   className="mgn-rgt-6"
                                   onChange={event => setInpStatusMsg(event.target.value)}
                                   placeholder="max. 72 chars" />
                            <button className={ 'btn-primary btn-fetch' + ( isStatusFetching ? ' fetching' : '' ) } 
                                    onClick={onClickUpdateStatusMsg}
                                    disabled={inpStatusMsg === ''}><span>Update</span></button>
                        </div>
                    </div>
                    { errorStatus !== null && <div className="input-error"><span>{errorStatus}</span></div> }
                </div>
                
                <div className="form-block">
                    <div className="form-element-wrapper">
                        <label>Tag your profile with exams</label>
                        <p>Add exam/session tags to your profile for more relevant content.</p>
                        <div>
                            {userTagsArr.length < 1 
                            ?
                            <div className="msg-no-tags">You haven't added any tags yet</div>
                            :
                            <ul className="list-tags">
                                {userTagsArr.map((option, idx) => {
                                    return (
                                        <li key={idx}>
                                            <div>{option.tag}</div>
                                            <button className="btn-primary"
                                                    onClick={() => onClickRemoveTag(option)}><span>Remove</span></button>
                                        </li>
                                    );
                                })}
                            </ul>
                            }
                        </div>
                    </div>
                    <div className="form-element-wrapper z-top mgn-btm-0">
                        <label className="pad-btm-10">Add exam channel tags to your profile</label>
                        <div className="selector-wrapper">
                            <div className="selector-static">
                                <button className="btn-selector"
                                        onClick={onClickToggleSelector}>
                                    { selectorOption.tag === 'Select' ? <span className="txt-select">Select</span> : selectorOption.tag}
                                </button>
                                <button className={ 'btn-primary btn-fetch' + ( isTagFetching ? ' fetching' : '' ) } 
                                        onClick={onClickAddTag}
                                        disabled={selectorOption.tag === 'Select'}><span>Add</span></button>
                            </div>
                            <div className={'selector-dynamic' + ( showSelector ? ' show' : '' )}>
                                <div className="selector-scroller">
                                    { examTagsArr.length < 1 
                                    ?
                                    <ul>
                                        <li>There are no tags to select</li>
                                    </ul>
                                    : 
                                    <ul>
                                        {examTagsArr.map((option, idx) => {
                                            return (
                                                <li key={idx}><button onClick={() => onClickSelectorOption(option)}>{option.tag}</button></li>
                                            );
                                        })}
                                    </ul>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    { errorTag !== null && <div className="input-error"><span>{errorTag}</span></div> }
                </div>
                
                <div className="form-block">
                    <div className="form-element-wrapper mgn-btm-0">
                        <label htmlFor="status-msg">Control how people see your profile</label>
                        <p>Decide what details to display on your public community profile.</p>
                        <div className="chkbox-group">
                            <div className="chkbox">
                                <label htmlFor="show-name">
                                    <input type="checkbox" id="show-name" checked={showName} onChange={event => onChangeCheckbox(event, { method: setShowName, updateType: 6, setbit: 2 })} />
                                    <span className="tick"></span>
                                    <span className="label">Show name</span>
                                </label>
                            </div>
                            <div className="chkbox">
                                <label htmlFor="show-tags">
                                    <input type="checkbox" id="show-tags" checked={showTags} onChange={event => onChangeCheckbox(event, { method: setShowTags, updateType: 6, setbit: 256 })} />
                                    <span className="tick"></span>
                                    <span className="label">Show exam tags</span>
                                </label>
                            </div>
                            { !user.isWPM &&
                            <div className="chkbox">
                                <label htmlFor="show-pin">
                                    <input type="checkbox" id="show-pin" checked={showPin} onChange={event => onChangeCheckbox(event, { method: setShowPin, updateType: 6, setbit: 128 })} />
                                    <span className="tick"></span>
                                    <span className="label">Add pin to Discover</span>
                                </label>
                            </div>
                            }
                        </div>
                    </div>
                    { errorChkbox !== null && <div className="input-error"><span>{errorChkbox}</span></div> }
                </div>
                
                <div className="form-block">
                    <div className="form-element-wrapper mgn-btm-0">
                        <label htmlFor="new-password" className="pad-btm-10"><span className="pad-rgt-10">Update password</span><span className="label-txt-prompt">(Between 6 and 12 characters. Include at least <br />one uppercase, one lowercase and one number.)</span></label>
                        <div className="inp-btn-wrapper mgn-btm-10">
                            <div className="inp-pwd-wrapper">
                                <input type={ newPasswordVisible ? 'text' : 'password' }  
                                    id="new-password" 
                                    minLength="6"
                                    maxLength="12"
                                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                                    value={newPassword} 
                                    onChange={event => onChangeValidate(event, 'password', true, null, { update: setNewPassword, validate: setNewPasswordValid, compareTo: null })}
                                    placeholder="New password" />
                                <button onClick={() => togglePwdVisibility({ method: setNewPasswordVisible, value: newPasswordVisible })} tabIndex={-1}><i className={ newPasswordVisible ? 'icon-eye-slash' : 'icon-eye' }></i></button>
                            </div>
                            <div className="btn-replacement"></div>
                        </div>
                        <div className="inp-btn-wrapper">
                            <div className="inp-pwd-wrapper">
                                <input type={ newConfirmVisible ? 'text' : 'password' }  
                                    id="new-confirm" 
                                    minLength="6"
                                    maxLength="12"
                                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                                    value={newConfirm} 
                                    onChange={event => onChangeValidate(event, 'compare', true, null, { update: setNewConfirm, validate: setNewConfirmValid, compareTo: newPassword })}
                                    placeholder="Confirm new password" />
                                <button onClick={() => togglePwdVisibility({ method: setNewConfirmVisible, value: newConfirmVisible })} tabIndex={-1}><i className={ newConfirmVisible ? 'icon-eye-slash' : 'icon-eye' }></i></button>
                            </div>
                            <button className={ 'btn-primary min-width-100 btn-fetch' + ( isPasswordFetching ? ' fetching' : '' ) }
                                    onClick={onClickUpdatePassword}
                                    disabled={ newPasswordValid && newConfirmValid ? false : true }><span>Update</span></button>
                        </div>
                    </div>
                    { errorPassword !== null && <div className="input-error"><span>{errorPassword}</span></div> }
                </div>
                
                <div className="form-block">
                    <div className="form-element-wrapper">
                        <button className="btn-tertiary full-width-400" 
                                onClick={() => onClickExtUrl('https://www.accaglobal.com/gb/en/footertoolbar/legal/acca-legal-and-copyright-statement.html')}>View terms of use</button>
                    </div>
                </div>
                
                <div className="form-block transparent">
                    <div className="form-element-wrapper">
                        <button className="btn-primary arrow-rgt btn-full-width"
                                onClick={() => onClickNavigateTo('home')}>
                            { winWidth < 401 ? 'Update your profile' : 'Finished updating your profile' }
                        </button>
                    </div>
                </div>
                    
            </div>
            <Footer />
        </div>
    );
    
}

export default Settings;
