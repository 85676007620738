import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Footer from '../layout/Footer';
import UserContext from '../../context/user/userContext';


const Community = () => {
    
    // Component state.
    const [parsedData, setParsedData]   = useState([]);
    const [lftColArr, setLftColArr]     = useState([]);
    const [rgtColArr, setRgtColArr]     = useState([]);
    
    // Reference context apis.
    const userContext                   = useContext(UserContext);
    
    // Reference hooks.
    const history                       = useHistory();
    
    // Reference route params.
    let { id }                          = useParams();
    
    // Destructure contexts.
    const { user,
            toggleMobileNav, 
            setCommunityQuery }         = userContext;
    
    
    
    /*
     * Component methods.
     */
    
    const onClickAppPage = event => {
        
        // Hide the mobile nav, even if not open.
        toggleMobileNav(false);
        
    }
    
    const onClickBtnChannel = tag => {
        
        // Set tag.
        setCommunityQuery('/tag?et=' + tag);
        
        // Navigate to the Community/Forum page using history push.
        history.push('/community/forum');
        
    }
    
    const divideColumns= () => {
        
        // Reference all unique category names.
        const uniqueCategoriesArr = [];
        
        user.tags.forEach(item => {
            
            if ( !uniqueCategoriesArr.includes(item.category) ) uniqueCategoriesArr.push(item.category);
            
        });
        
        // Divide categories into right & left column arrays.
        const midNum = Math.round(uniqueCategoriesArr.length / 2);
        
        setLftColArr(uniqueCategoriesArr.slice(0, midNum));
        setRgtColArr(uniqueCategoriesArr.slice(midNum, uniqueCategoriesArr.length));
        
    }
    
    const parseExamData = () => {
        
        const tmpArr    = [...parsedData];
        
        let tmpCatObj   = null;
        let tmpGrpObj   = null;
        
        user.tags.forEach(item => {
            
            // If Category already exists add item to it.
            if ( tmpArr.some( catObj => { tmpCatObj = catObj; return catObj.category === item.category; } ) ) {
                
                // If Group already exists add exam to it.
                if ( tmpCatObj.groups.some( grpObj => { tmpGrpObj = grpObj; return grpObj.categorySubTitle === item.categorySubTitle; } ) ) {
                    
                    tmpGrpObj.exams.push({
                        UNID    : item.UNID,
                        tag     : item.tag,
                        title   : item.title
                    });
                    
                }
                
                // Else create new Group & add exam.
                else {
                    
                    // Push new Group into the Groups array.
                    tmpCatObj.groups.push({
                        categorySubTitle    : item.categorySubTitle,
                        exams               : [
                            {
                                UNID    : item.UNID,
                                tag     : item.tag,
                                title   : item.title
                            }
                        ]
                    });
                    
                }
                
            }
            
            // Else create new Category & add item.
            else {
                
                // Push new Category into parsedData array.
                tmpArr.push({ 
                    category    : item.category,
                    groups      : [
                        {
                            categorySubTitle    : item.categorySubTitle,
                            exams               : [
                                {
                                    UNID    : item.UNID,
                                    tag     : item.tag,
                                    title   : item.title
                                }
                            ]
                        }
                    ]
                });
                
            }
            
        });
        
        setParsedData([...tmpArr]);
        
    }
    
    
    
    
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        // Parse the data into grouped exams so it
        // can be printed to the DOM in 2 columns.
        if ( user.tags.length > 0 ) {
            
            divideColumns();
            parseExamData();
            
        }
        
    }, [user.tags]);
    
    
    
    
    /*
     * Component view.
     */
    return (
        <div className="pages community" onClick={event => onClickAppPage(event)}>
            <div className="hero-img">
                <div className="fixed-width flex-row-dbl z-top">
                    <div className="hero-title">
                        Learning Community Channels
                    </div>
                    <div className="hero-box"></div>
                </div>
            </div> 
            <div className="fixed-width">
                
                <div className="mgn-top-20"> 
                    <h4 className="txt-light">Select a learning channel from the ACCA pathway:</h4>
                </div>
                
                <div className="lists-wrapper"> 
                    <div className="list-left">
                        
                        {parsedData.map((optionA, idxA) => {
                            
                            // Filter out 'Strategic Professional' category.
                            if ( lftColArr.includes(optionA.category) ) {
                                
                                return (
                                    <div key={idxA} 
                                         className="tag-category">
                                        <h5>{optionA.category}</h5>
                                        
                                        {optionA.groups.map((optionB, idxB) => {
                                            
                                            return (
                                                <div key={idxB}
                                                     className="tag-group">
                                                    <p className="mgn-btm-15">{optionB.categorySubTitle}</p>
                                                    
                                                    {optionB.exams.map((optionC, idxC) => {
                                                        
                                                        return (
                                                            <button key={idxC}
                                                                    className="btn-channel"
                                                                    onClick={() => onClickBtnChannel(optionC.tag)}>
                                                                <span>{optionC.tag}</span>
                                                                <span>
                                                                    <span>{optionC.title}</span>
                                                                </span>
                                                            </button>
                                                        );
                                                        
                                                    })}
                                                    
                                                </div>
                                            );
                                        
                                        })}
                                        
                                    </div>
                                );
                                
                            }
                            
                        })}

                    </div>
                    <div className="list-right">
                        
                        {parsedData.map((optionA, idxA) => {
                            
                            // Filter just Strategic Professional' category.
                            if ( rgtColArr.includes(optionA.category) ) {
                                
                                return (
                                    <div key={idxA} 
                                         className="tag-category">
                                        <h5>{optionA.category}</h5>
                                        
                                        {optionA.groups.map((optionB, idxB) => {
                                            
                                            return (
                                                <div key={idxB}
                                                     className="tag-group">
                                                    <p className="mgn-btm-15">{optionB.categorySubTitle}</p>
                                                    
                                                    {optionB.exams.map((optionC, idxC) => {
                                                        
                                                        return (
                                                            <button key={idxC}
                                                                    className="btn-channel"
                                                                    onClick={() => onClickBtnChannel(optionC.tag)}>
                                                                <span>{optionC.tag}</span>
                                                                <span>
                                                                    <span>{optionC.title}</span>
                                                                </span>
                                                            </button>
                                                        );
                                                        
                                                    })}
                                                    
                                                </div>
                                            );
                                        
                                        })}
                                        
                                    </div>
                                );
                                
                            }
                            
                        })}
                        
                    </div> 
                </div>
                
            </div>
            <Footer />
        </div>
    );
    
}

export default Community;
