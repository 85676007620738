import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ProtectedRoute from './components/hooks/ProtectedRoute';
import Navbar from './components/layout/Navbar';
import Home from './components/pages/Home';
import Community from './components/pages/Community';
import Forum from './components/pages/Forum';
import Discover from './components/pages/Discover';
import Settings from './components/pages/Settings';
import Help from './components/pages/Help';
import Modals from './components/layout/Modals';

import UserState from './context/user/UserState';

import './App.css';


function App() {

  return (
    <UserState>
      <Router>
        <div className="app">
          <Navbar />
          <Switch>
            <ProtectedRoute path="/question/community/:id" component={Forum} />
            <ProtectedRoute path="/community/forum/:id" component={Forum} />
            <ProtectedRoute path="/community/forum" component={Forum} />
            <ProtectedRoute path="/community" component={Community} />
            <ProtectedRoute path="/discover" component={Discover} />
            <ProtectedRoute path="/settings" component={Settings} />
            <Route path="/help"><Help /></Route>
            <Route path="/preview/discover"><Discover /></Route>
            <Route path="/:et"><Home /></Route>
            <Route path="*"><Home /></Route>
          </Switch>
          <Modals />
        </div>
      </Router>
    </UserState>
  );
  
}

export default App;
