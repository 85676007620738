import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { guestToken } from '../hooks/envVariables';
import UserContext from '../../context/user/userContext';


const ProtectedRoute = ({ component: Component, ...rest }) => {
    
    // Reference context apis.
    const userContext = useContext(UserContext);
            
    // Destructure contexts.
    const { isAuthenticated, 
            toggleLoginModal,
            isLogout } = userContext;
    
    // Check we have a valid 'learningcommunity' cookie (not a guest cookie).
    const cookies           = new Cookies();
    const token             = cookies.get('learningcommunity');
    const hasValidCookie    = typeof token !== 'undefined' && token !== guestToken ? true : false;
    
    return <Route { ...rest } render={props => { 
        
        switch (isAuthenticated && hasValidCookie) {
            case true:
                return <Component { ...props } />;
            case false:
                
                // Open a login modal & redirect to home page.
                // Unless the user has just logged out.
                if ( !isLogout ) toggleLoginModal(true);
            
                return <Redirect to="/" />;
            default:
                return null;    
        }
 
    }}/>
}

export default ProtectedRoute;
