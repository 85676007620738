import React from 'react';


const Footer = () => {
    
    const openExtLink = (url, newTab) => {
        
        if ( newTab ) window.open(url);
        else window.location.assign(url);
        
    }
    
    return (
        <footer>
             <div className="fixed-width">
                 <ul>
                     <li>
                         <button onClick={() => openExtLink('https://www.accaglobal.com/gb/en/student.html', true)} className="resources">
                             <span>ACCA Student Resources</span>
                             <span>www.accaglobal.com</span>
                         </button>
                    </li>
                    <li>
                         <button onClick={() => openExtLink('https://www.accaglobal.com/gb/en/footertoolbar/contact-us.html', true)} className="contact line">
                             <span>Contact Us</span>
                             <span>Contact information for your local office</span>
                         </button>
                    </li>
                 </ul>
            </div>
        </footer>
    );
    
}

export default Footer;
