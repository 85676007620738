import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { communityEndPoint } from '../hooks/envVariables';
import UserContext from '../../context/user/userContext';


const Forum = () => {
    
    // Component state.
    const [showSpinner, setShowSpinner]         = useState(true);
    const [showSelector, setShowSelector]       = useState(false);
    const [selectorOption, setSelectorOption]   = useState({ title: 'Select', tag: '', UNID: 0 });
    const [examTag, setExamTag]                 = useState('');
    
    // Reference context apis.
    const userContext               = useContext(UserContext);
    
    // Reference hooks.
    const history                   = useHistory();
    const location                  = useLocation();
    
    // Reference route params.
    let { id }                      = useParams();
    
    // Destructure contexts.
    const { user,
            toggleMobileNav, 
            communityQuery,
            setCommunityQuery }     = userContext;
    
    
    
    /*
     * Component methods.
     */
    
    const onClickAppPage = event => {
        
        const tgt = event.target;
        
        // If the drop down selector wasn't the target then hide it.
        if ( tgt.className !== 'btn-selector' ) setShowSelector(false);
        
        // Hide the mobile nav, even if not open.
        toggleMobileNav(false);
        
    }
    
    const onClickToggleSelector = () => {
        
        setShowSelector(!showSelector);
        
    }
    
    const onClickSelectorOption = (exam) => {
        
        // Call useState method to update user tag aray.
        setSelectorOption({ 
            title   : exam.title, 
            tag     : exam.tag,
            UNID    : exam.tag 
        });
        
        // Set tag.
        setCommunityQuery('/tag?et=' + exam.tag);
        
        // Navigate to the Community/Forum page using history push.
        history.push('/community/forum');
        
        // Hide the selector drop down.
        onClickToggleSelector();
        
    }
    
    
    
    
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        // Bail if no querystring exists.
        if ( location.search === '' ) return;
        
        // Reference querystring values.
        const qsValues = queryString.parse(location.search);
        
        // Bail if no et key exists in the querystring.
        // For a deeplink will show the post but user won't be in the right channel.
        if ( typeof qsValues.et === 'undefined' ) return;
        
        // Set the exam tag passed in from the Digest deeplink url.
        setExamTag(qsValues.et);
        
        // Get exam data that corresponds with given tag.
        const selExam  = user.tags.find(item => item.tag === qsValues.et);
       
        // Set current exam shown in the Channel selector.
        setSelectorOption({ 
            title   : selExam.title, 
            tag     : selExam.tag,
            UNID    : selExam.tag 
        });
        
    // The eslint comment below removes missing dependency warning from console.
    // eslint-disable-next-line
    }, []);
    
    useEffect(() => {

        // Set the initial exam the user selected.
        if ( communityQuery !== null && 
             communityQuery !== '' ) {
                 
            // Get the tag from the string.
            const selTag    = communityQuery.substring(communityQuery.indexOf('=') + 1);
            const selExam  = user.tags.find(item => item.tag === selTag);
            
            setSelectorOption({ 
                title   : selExam.title, 
                tag     : selExam.tag,
                UNID    : selExam.tag 
            });
            
        }
        
    }, [communityQuery]);
    
    
    /*
     * Component view.
     */
    return (
        <div className="pages forum no-scroll" onClick={event => onClickAppPage(event)}>
            <div className="hero-img forum"></div> 
            <div className="forum-selector"> 
                <div>
                    
                    <div className="hero-title">Learning Channels</div>
                    <div className="selector-wrapper">
                        <div className="selector-static">
                            <button className="btn-selector"
                                    onClick={onClickToggleSelector}>
                                { selectorOption.UNID === 0 ? <span className="txt-select">Select</span> : <><span className="txt-tag">{selectorOption.tag}</span><span className="txt-title">{selectorOption.title}</span></>}
                            </button>
                        </div>
                        <div className={'selector-dynamic' + ( showSelector ? ' show' : '' )}>
                            <div className="selector-scroller">
                                { user.tags.length < 1 
                                ?
                                <ul>
                                    <li>There are no exams to select</li>
                                </ul>
                                : 
                                <ul>
                                    {user.tags.map((option, idx) => {
                                        return (
                                            <li key={idx}><button onClick={() => onClickSelectorOption(option)}><span className="txt-tag">{option.tag}</span><span className="txt-title">{option.title}</span></button></li>
                                        );
                                    })}
                                </ul>
                                }
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className={'flex-fill-space iframe-wrapper' + ( showSpinner ? ' show' : '' )}>
                { typeof id !== 'undefined' ?
                    <iframe id='community-wrapper'
                            title='community-wrapper'
                            className='community-wrapper' 
                            src={communityEndPoint + '/community/' + id + '?et=' + examTag}
                            frameBorder='0' 
                            seamless='seamless' 
                            scrolling='yes'
                            onLoad={() => setShowSpinner(false)}>
                    </iframe>
                :
                    <iframe id='community-wrapper'
                            title='community-wrapper'
                            className='community-wrapper' 
                            src={communityQuery !== null ? communityEndPoint + communityQuery : communityEndPoint}
                            frameBorder='0' 
                            seamless='seamless' 
                            scrolling='yes'
                            onLoad={() => setShowSpinner(false)}>
                    </iframe>
                }
            </div>
        </div>
    );
    
}

export default Forum;
