export const SET_FETCHING               = 'SET_FETCHING';
export const LOGIN_USER                 = 'LOGIN_USER';
export const LOGIN_SUCCESS              = 'LOGIN_SUCCESS';
export const LOGIN_ERROR                = 'LOGIN_ERROR';
export const LOGOUT                     = 'LOGOUT';
export const USER_SUCCESS               = 'USER_SUCCESS';
export const USER_ERROR                 = 'USER_ERROR';
export const GUEST_USER_SUCCESS         = 'GUEST_USER_SUCCESS';
export const GUEST_USER_ERROR           = 'GUEST_USER_ERROR';
export const NAV_REDIRECT               = 'NAV_REDIRECT';
export const RESET_PWD_SUCCESS          = 'RESET_PWD_SUCCESS';
export const RESET_PWD_ERROR            = 'RESET_PWD_ERROR';
export const RESET_FORGOT_PWD_MSG       = 'RESET_FORGOT_PWD_MSG';
export const REGISTER_SUCCESS           = 'REGISTER_SUCCESS';
export const REGISTER_ERROR             = 'REGISTER_ERROR';
export const TOGGLE_LOGIN_MODAL         = 'TOGGLE_LOGIN_MODAL';
export const TOGGLE_REGISTER_MODAL      = 'TOGGLE_REGISTER_MODAL';
export const TOGGLE_ATTEST              = 'TOGGLE_ATTEST';
export const TOGGLE_REG_SUCCESS_MSG     = 'TOGGLE_REG_SUCCESS_MSG';
export const FORUM_DATA_SUCCESS         = 'FORUM_DATA_SUCCESS';
export const FORUM_DATA_ERROR           = 'FORUM_DATA_ERROR';
export const UPDATE_USER_SUCCESS        = 'UPDATE_USER_SUCCESS';
export const UPDATE_EMAIL_ERROR         = 'UPDATE_EMAIL_ERROR';
export const UPDATE_STATUS_ERROR        = 'UPDATE_STATUS_ERROR';
export const UPDATE_TAG_ERROR           = 'UPDATE_TAG_ERROR';
export const UPDATE_CHKBOX_ERROR        = 'UPDATE_CHKBOX_ERROR';
export const UPDATE_PASSWORD_ERROR      = 'UPDATE_PASSWORD_ERROR';
export const SET_STATUS_FETCHING        = 'SET_STATUS_FETCHING';
export const SET_TAG_FETCHING           = 'SET_TAG_FETCHING';
export const SET_TAG_REMOVE_FETCHING    = 'SET_TAG_REMOVE_FETCHING';
export const SET_PASSWORD_FETCHING      = 'SET_PASSWORD_FETCHING';
export const TOGGLE_MOBILE_NAV          = 'TOGGLE_MOBILE_NAV';
export const SET_COMMUNITY_QUERY        = 'SET_COMMUNITY_QUERY';
export const GET_STUDENTS_DATA          = 'GET_STUDENTS_DATA';
export const STUDENTS_DATA_SUCCESS      = 'STUDENTS_DATA_SUCCESS';
export const STUDENTS_DATA_ERROR        = 'STUDENTS_DATA_ERROR';
export const GET_EDUCATORS_DATA         = 'GET_EDUCATORS_DATA';
export const EDUCATORS_DATA_SUCCESS     = 'EDUCATORS_DATA_SUCCESS';
export const EDUCATORS_DATA_ERROR       = 'EDUCATORS_DATA_ERROR';
export const GET_MARKERS_DATA           = 'GET_MARKERS_DATA';
export const CLEAR_MARKERS_DATA         = 'CLEAR_MARKERS_DATA';
export const MARKERS_DATA_SUCCESS       = 'MARKERS_DATA_SUCCESS';
export const MARKERS_DATA_ERROR         = 'MARKERS_DATA_ERROR';
export const GET_SEARCH_FILTER_DATA     = 'GET_SEARCH_FILTER_DATA';
export const SEARCH_FILTER_DATA_SUCCESS = 'SEARCH_FILTER_DATA_SUCCESS';
export const SEARCH_FILTER_DATA_ERROR   = 'SEARCH_FILTER_DATA_ERROR';
export const UNMOUNT_DISCOVER           = 'UNMOUNT_DISCOVER';
export const SET_DISCOVER_PREVIEW       = 'SET_DISCOVER_PREVIEW';       
