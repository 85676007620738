import React, { useState, useContext, useRef, useEffect } from 'react';
import Modal from 'react-modal';

import UserContext from '../../context/user/userContext';


const Modals = () => {
    
    // Define components state hooks.
    const [currentYear, setCurrentYear]                     = useState(2020);
    const [inpLoginEmail, setInpLoginEmail]                 = useState('');
    const [inpLoginPassword, setInpLoginPassword]           = useState('');
    const [inpForgotId, setInpForgotId]                     = useState('');
    const [inpForgotEmail, setInpForgotEmail]               = useState('');
    const [inpRegisterId, setInpRegisterId]                 = useState('');
    const [inpRegisterDay, setInpRegisterDay]               = useState('');
    const [inpRegisterMonth, setInpRegisterMonth]           = useState('');
    const [inpRegisterYear, setInpRegisterYear]             = useState('');
    const [inpRegisterPassword, setInpRegisterPassword]     = useState('');
    const [inpRegisterConfirm, setInpRegisterConfirm]       = useState(''); 
    const [inpRememberMe, setInpRememberMe]                 = useState(true);
    const [switchToLogin, setSwitchToLogin]                 = useState(false);
    const [switchToRegister, setSwitchToRegister]           = useState(false);
    const [isForgotPassword, setIsForgotPassword]           = useState(false);
    const [loginEmailValid, setLoginEmailValid]             = useState(false);
    const [loginPwdValid, setLoginPwdValid]                 = useState(false);
    const [forgotEmailValid, setForgotEmailValid]           = useState(false);
    const [forgotIdValid, setForgotIdValid]                 = useState(false);
    const [registerIdValid, setRegisterIdValid]             = useState(false);
    const [registerDayValid, setRegisterDayValid]           = useState(false);
    const [registerMonthValid, setRegisterMonthValid]       = useState(false);
    const [registerYearValid, setRegisterYearValid]         = useState(false);
    const [registerPwdValid, setRegisterPwdValid]           = useState(false);
    const [registerCfmValid, setRegisterCfmValid]           = useState(false);
    const [btnLoginDisabled, setBtnLoginDisabled]           = useState(true);
    const [btnForgotDisabled, setBtnForgotDisabled]         = useState(true);
    const [btnRegisterDisabled, setBtnRegisterDisabled]     = useState(true);
    const [loginPwdVisible, setLoginPwdVisible]             = useState(false);
    const [registerPwdVisible, setRegisterPwdVisible]       = useState(false);
    const [registerCfmVisible, setRegisterCfmVisible]       = useState(false);
    
    // Element reference hooks.
    const refLoginEmail             = useRef(null);
    const refLoginPwd               = useRef(null);
    const refForgotEmail            = useRef(null);
    const refForgotId               = useRef(null);
    const refRegisterId             = useRef(null);
    const refRegisterDay            = useRef(null);
    const refRegisterMonth          = useRef(null);
    const refRegisterYear           = useRef(null);
    const refRegisterPwd            = useRef(null);
    const refRegisterCfm            = useRef(null);
    
    // Reference context apis.
    const userContext               = useContext(UserContext);
    
    // Destructure contexts.
    const { showLogin, 
            showRegister, 
            showRegSuccessMsg, 
            toggleLoginModal, 
            toggleRegisterModal, 
            login, 
            resetPassword, 
            isFetching, 
            error, 
            forgotPwdMsg, 
            resetForgotPwdMsg, 
            register, 
            toggleAttest, 
            toggleAttestModal, 
            navRedirect, 
            toggleRegSuccessMsgModal } = userContext;
    
    // Set modal to body for accessibility.
    Modal.setAppElement('body');
    

    
    /*
     * Component methods.
     */
    
    const onKeyupFocusNext = (event, props) => {
        
        const tgt       = event.target;

        if ( event.key === 'Tab' || 
             event.key === 'Shift' || 
             event.key === 'ArrowLeft' ||
             event.key === 'ArrowRight' ) {

            // Do nothing...
            
        }
        else {
            
            if ( tgt.value.length >= tgt.maxLength ) props.nextInp.current.focus();
            
        }

    }
    
    const onChangeValidate = (event, type, validate, maxValue, methods) => {
        
        const tgt       = event.target;
        const numArr    = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        const valArr    = [...tgt.value];
        const pwdRegex  = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
        
        let validNum    = true;
        
        // If validation requested validate value, this will
        // enable/disable submit button.
        if ( validate ) {
            
            switch (type) {
                case 'email':
                    methods.validate(tgt.value !== '' && ( tgt.validity !== null && tgt.validity.valid ) ? true : false); 
                    methods.update(tgt.value); 
                    break;
                case 'number':
                    
                    valArr.forEach((item, idx) => {
                        
                        if ( !numArr.includes(item) ) validNum = false;
                        
                    });
                    
                    if ( validNum ) {
                        
                        methods.validate(tgt.value !== '' ? true : false);
                        methods.update(tgt.value);
                        
                    }

                    break;
                case 'date':
                    
                    valArr.forEach((item, idx) => {
                            
                        if ( !numArr.includes(item) ) validNum = false;
                        
                    });
                
                    if ( validNum ) {
                    
                        methods.validate(tgt.value !== '' && tgt.value <= maxValue ? true : false);
                        methods.update(tgt.value);
                    
                    }

                    break;
                case 'password':
                    methods.validate(tgt.value !== '' && pwdRegex.test(tgt.value) ? true : false);
                    methods.update(tgt.value);
                    break;
                case 'compare':
                    methods.validate(tgt.value !== '' && tgt.value === methods.compareTo ? true : false);
                    methods.update(tgt.value);
                    break;
                default:
                    methods.validate(tgt.value !== '' ? true : false);
                    methods.update(tgt.value);
            }
            
        }
        
        // No validation requested, update value.
        else methods.update(tgt.value);
 
    }
    
    const resetLoginInputs = () => {
        
        setInpLoginEmail('');
        setInpLoginPassword('');

    }
    
    const resetRegisterInputs = () => {
            
        // Add delay to resetting to give modal time to fade out.
        setTimeout(() => {
            
            setInpRegisterId('');
            setInpRegisterDay('');
            setInpRegisterMonth('');
            setInpRegisterYear('');
            setInpRegisterPassword('');
            setInpRegisterConfirm('');
            
            toggleAttestModal(false);
            toggleRegSuccessMsgModal(false);
            
        }, 500);
        
  
    }
    
    const hideLogin = () => {
        
        // Open a login modal through the context api.
        toggleLoginModal(false);
        
        // Clear form inputs.
        resetLoginInputs();
        
        // Reset redirect prop.
        navRedirect({ doRedirect: false, redirectTo: null });
        
    }
    
    const hideRegister = () => {
        
        // Open a login modal through the context api.
        toggleRegisterModal(false);
        
        // Clear form inputs.
        resetRegisterInputs();
        
        // Reset redirect prop.
        navRedirect({ doRedirect: false, redirectTo: null });
        
    }

    const onClickLogin = () => {
        
        // Show error msg.
        setInputErrorMsg(loginEmailValid, refLoginEmail, 'Please check your email address and try again');
        setInputErrorMsg(loginPwdValid, refLoginPwd, 'Please check your password and try again');
        
        // If we have valid data post it to the server.
        if ( loginEmailValid && loginPwdValid ) {
            
            // Collect request data.
            const reqData = {
                username    : inpLoginEmail,
                password    : inpLoginPassword,
                code        : inpLoginPassword,
                rememberMe  : inpRememberMe
            };
            
            login(reqData);
            
        }

    }
    
    const onClickResetPassword = () => {
        
        // Show error msg.
        setInputErrorMsg(forgotEmailValid, refForgotEmail, 'Please check your email address and try again');
        setInputErrorMsg(forgotIdValid, refForgotId, 'Please check your id and try again');
        
        // If we have valid data post it to the server.
        if ( forgotEmailValid && forgotIdValid ) {
            
            // Collect request data.
            const reqData = { 
                RegistrationCode    : inpForgotId, 
                Email               : inpForgotEmail 
            };
            
            resetPassword(reqData);
            
        }
        
    }
    
    const onClickRegister = () => {
        
        // If passwords are not the requred length & don't match inform user.
        if ( inpRegisterPassword.length < 6 ) setInputErrorMsg(registerPwdValid, refRegisterPwd, 'Your password must be between 6 and 12 characters');
        if ( inpRegisterConfirm.length < 6 ) setInputErrorMsg(registerCfmValid, refRegisterCfm, 'Your password must be between 6 and 12 characters');
        
        // Send data to server... 
        // If we have valid data show ATTEST modal, where user can attest to register.
        if ( registerIdValid && registerDayValid && 
             registerMonthValid && registerYearValid && 
             registerPwdValid && registerCfmValid ) {
            
            toggleAttestModal(true);
            
        }
        
    }
    
    const onClickTermsAccept = () => {
        
        // Collect request data.
        const reqData = {
            regId       : inpRegisterId,
            dob         : inpRegisterDay + '-' + inpRegisterMonth + '-' + inpRegisterYear,
            password    : inpRegisterPassword
        };
        
        navRedirect({ doRedirect: true, redirectTo: 'settings' });
        register(reqData);
        
    }
    
    const onClickSwitchToRegister = () => {
        
        // Set a flag to open Register modal when Login modal has closed.
        setSwitchToRegister(true);
        
        // Close Login modal.
        toggleLoginModal(false);
        
    }
    
    const onClickSwitchToLogin = () => {
        
        // Set a flag to open Login modal when Register modal has closed.
        setSwitchToLogin(true);
        
        // Close Register modal.
        toggleRegisterModal(false);
        
    }
    
    const onLoginClosed = () => {
        
        // If requested open Register modal.
        if ( switchToRegister ) toggleRegisterModal(true);
        
        // Remove flag to open Register modal when Login modal has closed.
        setSwitchToRegister(false);
        
    }
    
    const onRegisterClosed = () => {
        
        // If requested open Login modal.
        if ( switchToLogin ) toggleLoginModal(true);
        
        // Remove flag to open Login modal when Register modal has closed.
        setSwitchToLogin(false);
        
    }
    
    const onClickForgottenPassword = () => {
        
        // Reset forgot pasword message, even if now set.
        resetForgotPwdMsg();
        
        // Switch to forgot password modal.
        setIsForgotPassword(true);
        
    }
    
    const onClickBackToLogin = () => {
        
        // Switch to Login form.
        setIsForgotPassword(false);
        
        // Clear Forgot form inputs.
        setInpForgotId('');
        setInpForgotEmail('');
        
        // Reset forgot pasword message, even if now set.
        resetForgotPwdMsg();
        
    }
    
    const onClickExtUrl = (url) => {
        
        window.open(url);
        
    }
    
    const setInputErrorMsg = (isValid, inpRef, msg) => {
        
        if ( inpRef.current.nextSibling === null ) return false;
        
        if ( isValid ) inpRef.current.nextSibling.innerHTML = '';
        else inpRef.current.nextSibling.innerHTML = msg;
        
    }
    
    const togglePwdVisibility = state => state.method(!state.value);
    
    const onKeyupLogin = event => {
        
        if ( event.key === 'Enter' && loginEmailValid && loginPwdValid ) onClickLogin();
        
    }
    
    const onKeyupRegister = event => {
        
        if ( event.key === 'Enter' &&
             registerIdValid && 
             registerDayValid && 
             registerMonthValid && 
             registerYearValid && 
             registerPwdValid && 
             registerCfmValid ) onClickRegister();
        
    }

    
    
    
    /*
     * Component hooks.
     */
    
    useEffect(() => {

        // Set current year variable.
        const date = new Date();
        setCurrentYear(date.getFullYear());
        
        // The eslint comment below removes missing dependency warning from console.
        // eslint-disable-next-line
    }, []);
    
    useEffect(() => {
        
        // Toggle LOGIN button.
        if ( loginEmailValid && loginPwdValid ) setBtnLoginDisabled(false);
        else setBtnLoginDisabled(true);

        // Toggle FORGOT button.
        if ( forgotEmailValid && forgotIdValid ) setBtnForgotDisabled(false);
        else setBtnForgotDisabled(true);
        
        // Toggle REGISTER button.
        if ( registerIdValid && 
             registerDayValid && 
             registerMonthValid && 
             registerYearValid && 
             registerPwdValid && 
             registerCfmValid ) setBtnRegisterDisabled(false);
        else setBtnRegisterDisabled(true);
        
        if ( !showLogin ) resetLoginInputs();
        if ( !showRegister ) resetRegisterInputs();
        
        // The eslint comment below removes missing dependency warning from console.
        // eslint-disable-next-line
    }, [loginEmailValid, loginPwdValid, forgotEmailValid, forgotIdValid, registerIdValid, registerDayValid, registerMonthValid, registerYearValid, registerPwdValid, registerCfmValid, showLogin, showRegister]);
    
    
     

    
    /*
     * Component view.
     */
    return (
        <>
            <Modal isOpen={showLogin}
                className="cus-modal login"
                overlayClassName="cus-modal-overlay"
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                onRequestClose={hideLogin}
                onAfterClose={onLoginClosed}
                closeTimeoutMS={300}>
                   
                { isForgotPassword ? 
                <> 
                    <h3>Forgotten password?</h3>
                    { forgotPwdMsg !== null ?
                    <>
                        <p className="pad-top-15 pad-btm-60">{forgotPwdMsg}</p>
                        <div className="flex-row-dbl">
                            <button className="btn-primary arrow-lft" 
                                    onClick={onClickBackToLogin}>Back to login</button>
                        </div>
                    </>
                    :
                    <>
                        <p className="pad-btm-10">Enter your details and click on reset below and we will send you a password reset email.</p>
                        <div className="form-element-wrapper">
                            <label htmlFor="forgot-id">Your ACCA registration ID<sup className="txt-red">*</sup></label>
                            <input type="text" 
                                id="forgot-id" 
                                maxLength="50"
                                value={inpForgotId} 
                                ref={refForgotId}
                                onChange={event => onChangeValidate(event, 'text', true, null, { update: setInpForgotId, validate: setForgotIdValid, compareTo: null })} />
                            <div className="input-error"></div>
                        </div>
                        <div className="flex-row-dbl">
                            <div></div>
                            <div>
                                <button className="btn-style-link txt-blue" onClick={() => onClickExtUrl('https://portal.accaglobal.com/irxweb/bespokeredirect/faces/page/public/tracking/RetrieveClient.jspx')}>Forgotten ID?</button>
                            </div>
                        </div>
                        <div className="form-element-wrapper">
                            <label htmlFor="forgot-email">Your email<sup className="txt-red">*</sup></label>
                            <input type="email" 
                                id="forgot-email" 
                                maxLength="128"
                                value={inpForgotEmail} 
                                ref={refForgotEmail}
                                onChange={event => onChangeValidate(event, 'email', true, null, { update: setInpForgotEmail, validate: setForgotEmailValid, compareTo: null })}
                                placeholder="e.g. user@email.com" />
                            <div className="input-error"></div>
                        </div>
                        <div className={'server-error-msg' + ( error !== null ? ' show' : '' )}>{error}</div>
                        <div className="flex-row-dbl">
                            <button className="btn-secondary" 
                                    onClick={onClickBackToLogin}>Back</button>
                            <button className="btn-primary" 
                                    onClick={onClickResetPassword} 
                                    disabled={btnForgotDisabled}>Reset</button>
                        </div>
                    </>
                    }
                </>
                :
                <>
                    <h3>Log in to the Learning Community</h3>
                    <p className="pad-btm-10">You need to be <button className="btn-style-link txt-blue" onClick={onClickSwitchToRegister}>registered</button> to join the community.</p>
                    <div className="form-element-wrapper pad-btm-0">
                        <label htmlFor="login-email">Your ACCA registered email address<sup className="txt-red">*</sup></label>
                        <input type="email" 
                            id="login-email" 
                            maxLength="128"
                            value={inpLoginEmail} 
                            ref={refLoginEmail}
                            onChange={event => onChangeValidate(event, 'email', true, null, { update: setInpLoginEmail, validate: setLoginEmailValid, compareTo: null })}
                            placeholder="e.g. user@email.com" />
                        <div className="input-error"></div>
                    </div>
                    <div className="form-element-wrapper">
                        <label htmlFor="login-password">Password<sup className="txt-red">*</sup></label>
                        <div className="inp-pwd-wrapper">
                            <input type={ loginPwdVisible ? 'text' : 'password' } 
                                id="login-password" 
                                maxLength="12"
                                value={inpLoginPassword} 
                                ref={refLoginPwd}
                                onKeyUp={event => onKeyupLogin(event)}
                                onChange={event => onChangeValidate(event, 'text', true, null, { update: setInpLoginPassword, validate: setLoginPwdValid, compareTo: null })} />
                            <button onClick={() => togglePwdVisibility({ method: setLoginPwdVisible, value: loginPwdVisible })} tabIndex={-1}><i className={ loginPwdVisible ? 'icon-eye-slash' : 'icon-eye' }></i></button>
                        </div>
                        <div className="input-error"></div>
                    </div>
                    <div className="flex-row-dbl pad-top-5 flex-wrap-400">
                        <div className="chkbox reverse">
                            <label htmlFor="remember-me">
                                <input type="checkbox" id="remember-me" checked={inpRememberMe} onChange={event => setInpRememberMe(event.target.checked)} />
                                <span className="tick"></span>
                                <span className="label">Remember me</span>
                            </label>
                        </div>
                        <div>
                            <button className="btn-style-link txt-blue" onClick={onClickForgottenPassword}>Forgotten password?</button>
                        </div>
                    </div>
                    <div className={'server-error-msg' + ( error !== null ? ' show' : '' )}>{error}</div>
                    <div className="flex-row-dbl">
                        <button className="btn-secondary" 
                                onClick={hideLogin}>Cancel</button>
                        <button className={ 'btn-primary btn-fetch' + ( isFetching ? ' fetching' : '' ) } 
                                onClick={onClickLogin} 
                                disabled={btnLoginDisabled}><span>Login</span></button>
                    </div>
                </>
                }
                
            </Modal>
            <Modal isOpen={showRegister}
                className={'cus-modal register' + ( toggleAttest ? '' : ' stretch' )}
                overlayClassName="cus-modal-overlay"
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                onRequestClose={hideRegister}
                onAfterClose={onRegisterClosed}
                closeTimeoutMS={300}>  
                { toggleAttest ?
                <>
                    { showRegSuccessMsg ?
                    <>   
                        <h3>You are now registered</h3>
                        <p className="pad-btm-10">
                            {`Please log in with your ACCA registered email and the password you just entered. Click the button below to go to the login screen.`}
                        </p>
                        <div className="flex-row-dbl pad-top-40">
                            <button className="btn-secondary" 
                                    onClick={hideRegister}>Cancel</button>
                            <button className={ 'btn-primary btn-fetch' + ( isFetching ? ' fetching' : '' ) } 
                                    onClick={onClickSwitchToLogin}><span>Go to login</span></button>
                        </div>
                    </>
                    :
                    <>
                        <h3>Confirm terms</h3>
                        <p className="pad-btm-10">
                            By clicking the 'I accept' button below, all users of this site agree to be bound by <a className="a-txt-link" href="http://www.accaglobal.com/en/footertoolbar/legal.html" target="_blank" rel="noopener noreferrer">ACCA's website terms of use</a> and accept that they must act professionally in all their discussions and interactions with others on this site.
                        </p>
                        <p className="pad-btm-10">
                            {`The views expressed on the forums are not necessarily those of ACCA. In addition, site users acknowledge that whilst ACCA is 
                            the provider of this interactive service, we are under no obligation to oversee, monitor or moderate the service, and we expressly 
                            exclude our liability for any loss or damage arising from the use of any interactive service by a user in contravention of our 
                            website terms of use, whether it is moderated or not.`}
                        </p>
                        <div className="flex-row-dbl pad-top-40">
                            <button className="btn-secondary" 
                                    onClick={hideRegister}>Cancel</button>
                            <button className={ 'btn-primary btn-fetch' + ( isFetching ? ' fetching' : '' ) } 
                                    onClick={onClickTermsAccept}
                                    disabled={btnRegisterDisabled}><span>I accept</span></button>
                        </div>
                    </>
                    }
                </>
                :
                <>
                    <h3>Register with the Learning Community</h3>
                    <p className="pad-btm-10">
                        You need an ACCA registered email address to create and log in to your Learning Community account.
                    </p>
                    <p className="pad-btm-10">
                        Please note that it may take up to 7 days from the date of your initial registration with ACCA to register for a Learning Community account.
                    </p>
                    <div className="form-element-wrapper">
                        <label htmlFor="register-id">Your 7 digit ACCA registration ID number<sup className="txt-red">*</sup></label>
                        <input type="text" 
                            id="register-id" 
                            minLength="7"
                            maxLength="7"
                            ref={refRegisterId}
                            value={inpRegisterId}
                            onKeyUp={event => onKeyupFocusNext(event, { nextInp: refRegisterDay })}
                            onChange={event => onChangeValidate(event, 'number', true, null, { update: setInpRegisterId, validate: setRegisterIdValid, compareTo: null })}
                            placeholder="e.g. 1234567" />
                        <div className="input-error"></div>
                    </div>
                    <div className="flex-row-dbl">
                        <div></div>
                        <div>
                            <button className="btn-style-link txt-blue" 
                                    tabIndex={-1}
                                    onClick={() => onClickExtUrl('https://portal.accaglobal.com/irxweb/bespokeredirect/faces/page/public/tracking/RetrieveClient.jspx')}>Forgotten ID?</button>
                        </div>
                    </div>
                    <div className="form-element-wrapper">
                        <label htmlFor="register-day">Your date of birth<sup className="txt-red">*</sup></label>
                        <div className="dmy-wrapper">
                            <input type="text" 
                                id="register-day" 
                                placeholder="dd"
                                maxLength="2"
                                ref={refRegisterDay}
                                value={inpRegisterDay} 
                                className={ inpRegisterDay !== '' && !registerDayValid ? 'invalid' : null }
                                onKeyUp={event => onKeyupFocusNext(event, { nextInp: refRegisterMonth })}
                                onChange={event => onChangeValidate(event, 'date', true, 31, { update: setInpRegisterDay, validate: setRegisterDayValid, compareTo: null })} />
                            <span>/</span>
                            <input type="text" 
                                id="register-month" 
                                placeholder="mm"
                                maxLength="2"
                                ref={refRegisterMonth}
                                value={inpRegisterMonth} 
                                className={ inpRegisterMonth !== '' && !registerMonthValid ? 'invalid' : null }
                                onKeyUp={event => onKeyupFocusNext(event, { nextInp: refRegisterYear })}
                                onChange={event => onChangeValidate(event, 'date', true, 12, { update: setInpRegisterMonth, validate: setRegisterMonthValid, compareTo: null })} />
                            <span>/</span>
                            <input type="text" 
                                id="register-year" 
                                placeholder="yyyy"
                                maxLength="4"
                                ref={refRegisterYear}
                                value={inpRegisterYear} 
                                className={ inpRegisterYear !== '' && !registerYearValid ? 'invalid' : null }
                                onKeyUp={event => onKeyupFocusNext(event, { nextInp: refRegisterPwd })}
                                onChange={event => onChangeValidate(event, 'date', true, currentYear, { update: setInpRegisterYear, validate: setRegisterYearValid, compareTo: null })} />
                        </div>
                    </div>
                    <div className="form-element-wrapper">
                        <label htmlFor="register-password">Choose a password<sup className="txt-red pad-rgt-10">*</sup><span className="label-txt-prompt">(Between 6 and 12 characters. Include at least <br />one uppercase, one lowercase and one number.)</span></label>
                        <div className="inp-pwd-wrapper">
                            <input type={ registerPwdVisible ? 'text' : 'password' } 
                                id="register-password" 
                                minLength="6"
                                maxLength="12"
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                                ref={refRegisterPwd}
                                value={inpRegisterPassword} 
                                onChange={event => onChangeValidate(event, 'password', true, null, { update: setInpRegisterPassword, validate: setRegisterPwdValid, compareTo: null })} />
                            <button onClick={() => togglePwdVisibility({ method: setRegisterPwdVisible, value: registerPwdVisible })} tabIndex={-1}><i className={ registerPwdVisible ? 'icon-eye-slash' : 'icon-eye' }></i></button>
                        </div>
                        <div className="input-error"></div>
                    </div>
                    <div className="form-element-wrapper">
                        <label htmlFor="register-confirm">Confirm password<sup className="txt-red">*</sup></label>
                        <div className="inp-pwd-wrapper">
                            <input type={ registerCfmVisible ? 'text' : 'password' } 
                                id="register-confirm" 
                                minLength="6"
                                maxLength="12"
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                                ref={refRegisterCfm}
                                value={inpRegisterConfirm} 
                                className={ inpRegisterConfirm !== '' && inpRegisterConfirm !== inpRegisterPassword ? 'invalid' : null }
                                onKeyUp={event => onKeyupRegister(event)}
                                onChange={event => onChangeValidate(event, 'compare', true, null, { update: setInpRegisterConfirm, validate: setRegisterCfmValid, compareTo: inpRegisterPassword })} />
                            <button onClick={() => togglePwdVisibility({ method: setRegisterCfmVisible, value: registerCfmVisible })} tabIndex={-1}><i className={ registerCfmVisible ? 'icon-eye-slash' : 'icon-eye' }></i></button>
                        </div>
                        <div className="input-error"></div>
                    </div>
                    <div className="form-element-wrapper reg-terms">
                        By clicking the "Register" button you agree to the <button className="btn-style-link txt-blue" onClick={() => onClickExtUrl('http://www.accaglobal.com/en/footertoolbar/legal.html')}>ACCA's website terms of use</button>.
                    </div>
                    <div className={'server-error-msg register-error' + ( error !== null ? ' show' : '' )}>{error}</div>
                    <div className="flex-row-dbl">
                        <button className="btn-secondary" 
                                onClick={hideRegister}>Cancel</button>
                        <button className={ 'btn-primary btn-fetch' + ( isFetching ? ' fetching' : '' ) } 
                                onClick={onClickRegister}
                                disabled={btnRegisterDisabled}><span>Register</span></button>
                    </div>
                </>
                }
            </Modal>
        </>
    )
    
}

export default Modals;
