import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { guestToken } from '../hooks/envVariables';
import UserContext from '../../context/user/userContext';


let studentsResultsArr              = [];
let educatorsResultsArr             = [];

// Animation array.
const animationArr = [
  {
    name          : 'All Students',
    searchFilter  : 1024,
    tagUNID       : 0,
    date          : null
  },
  {
    name          : 'All Learning Partners',
    searchFilter  : 1,
    tagUNID       : null,
    date          : null
}];

const studentsKeyPin                = 'img/student_pin_key.png';
const educatorsKeyPin               = 'img/educator_pin_key.png';

let isGuest                         = false;

let timer01                         = null;
let timer02                         = null;
let timer03                         = null;
let timer04                         = null;
let timer05                         = null;


const Discover = () => {
    
    // Define component variables.
    const numMaxResults             = 100;
    const numGetresults             = 4;
    const initZoom                  = 2;
    const minZoom                   = 2; // Zoomed out, 0/whole world.
    const maxZoom                   = 10; // Zoomed in, changes at different locations.
    const studentsDelay             = 30;
    const educatorsDelay            = 30;
    const brandDelay                = 3;
    
    
    // Define components state hooks.
    const [isMapReady, setIsMapReady]                           = useState(false);
    const [studentsFilterArr, setStudentsFilterArr]             = useState([{ UNID: 0, tag: 'All', title: 'All exams' }, { UNID: -1, tag: 'Same', title: 'Students studying same exams' }]);
    const [educatorsFilterArr, setEducatorsFilterArr]           = useState([]);
    const [inpSearchStudents, setInpSearchStudents]             = useState('');
    const [inpSearchEducators, setInpSearchEducators]           = useState('');
    const [isStudentsFetching, setIsStudentsFetching]           = useState(false);
    const [isEducatorsFetching, setIsEducatorsFetching]         = useState(false);
    const [isMarkersFetching, setIsMarkersFetching]             = useState(false);
    const [showStudentsSelector, setShowStudentsSelector]       = useState(false);
    const [showEducatorsSelector, setShowEducatorsSelector]     = useState(false);
    const [inpStudentsGo, setInpStudentsGo]                     = useState(true);
    const [inpEducatorsGo, setInpEducatorsGo]                   = useState(true);
    const [isMoreStudents, setIsMoreStudents]                   = useState(true);
    const [isMoreEducators, setIsMoreEducators]                 = useState(true);
    const [selectorStudentsOption, setSelectorStudentsOption]   = useState(null);
    const [refCurStudentsOption, setRefCurStudentsOption]       = useState(null);
    const [selectorEducatorsOption, setSelectorEducatorsOption] = useState(null);
    const [refCurEducatorsOption, setRefCurEducatorsOption]     = useState(null);
    const [inpStudentsPlaceholder, setInpStudentsPlaceholder]   = useState(null);
    const [inpEducatorsPlaceholder, setInpEducatorsPlaceholder] = useState(null);
    const [studentsListPoolArr, setStudentsListPoolArr]         = useState(null);
    const [studentsListArr, setStudentsListArr]                 = useState(null);
    const [educatorsListPoolArr, setEducatorsListPoolArr]       = useState(null);
    const [educatorsListArr, setEducatorsListArr]               = useState(null);
    const [gMap, setGMap]                                       = useState(null);
    const [resultsMapArr, setResultsMapArr]                     = useState([]);
    const [userCoords, setUserCoords]                           = useState({ lat: 51.507351, lng: -0.127758 });
    const [showMapSelector, setShowMapSelector]                 = useState(false);
    const [mapSelectorOption, setMapSelectorOption]             = useState({ name: 'All Students', searchFilter: 1024, tagUNID: 0 });
    const [mapSelectorArr, setMapSelectorArr]                   = useState([]);
    const [refreshMap, setRefreshMap]                           = useState(false);
    const [isMapAnimating, setIsMapAnimating]                   = useState(false);
    const [curAnimateName, setCurAnimateName]                   = useState(null);
    const [allowSelectorFetch, setAllowSelectorFetch]           = useState(true);
    const [isMapKeyStudent, setIsMapKeyStudent]                 = useState(true);
    const [zoomLevelStudentsMsg, setZoomLevelStudentsMsg]       = useState('');
    const [zoomLevelEducatorsMsg, setZoomLevelEducatorsMsg]     = useState('');
    const [isBrandReady, setIsBrandReady]                       = useState(false);
    const [doFirstRun, setDoFirstRun]                           = useState(false);
    const [markersLoading, setMarkersLoading]                   = useState(false);
    const [zoomQuadOut, setZoomQuadOut]                         = useState(false);
    const [nextExamDate, setNextExamDate]                       = useState('');
    const [sailOn, setSailOn]                                   = useState(false);

    
    // Reference context apis.
    const userContext               = useContext(UserContext);
    
    
    // Reference hooks.
    const history                   = useHistory();
    
    
    // Element reference hooks.
    const refMap                    = useRef(null);
    const refInpStudents            = useRef(null);
    const refInpEducators           = useRef(null);
    const animateInterval           = useRef(null);
    const animateIndex              = useRef(1);
    const mapBounds                 = useRef(null);
    const boundsChanged             = useRef(false);
    const curAnimateObj             = useRef(null);
    const markersArr                = useRef([]);
    const curSearchFilter           = useRef(1024);
    const curTagUNID                = useRef(0);
    
    
    // Destructure contexts.
    const { toggleMobileNav, 
            toggleLoginModal, 
            toggleRegisterModal, 
            setCommunityQuery, 
            getDiscoverData, 
            getMarkersData, 
            clearMarkersData, 
            studentsData, 
            educatorsData, 
            markersData, 
            errorStudents, 
            errorEducators, 
            errorMarkers, 
            getSearchFilterData, 
            searchFilterData, 
            user, 
            nowUnmountDiscover, 
            isAuthenticated, 
            callApiGuestUser, 
            setDiscoverPreview }    = userContext;
    
    
    
    /*
     * Component logic.
     */
    
    // Check the ROUTE this component is serving. If route is
    // PREVIEW then user is not logged in so don't fetch student data.
    const routeMatch                = useRouteMatch('/preview/discover');
    const isPreview                 = routeMatch !== null && routeMatch.isExact ? true : false;
    
    
    
    
    
    /*
     * Component methods.
     */

    const onClickAppPage = event => {
        
        const tgt = event.target;
        
        // If the drop down selector wasn't the target then hide it.
        if ( tgt.className !== 'btn-selector students' ) setShowStudentsSelector(false);
        if ( tgt.className !== 'btn-selector educators' ) setShowEducatorsSelector(false);
        if ( tgt.className !== 'btn-selector map' ) setShowMapSelector(false);
        
        // Hide the mobile nav, even if not open.
        toggleMobileNav(false);
        
    }
    
    const renderMap = () => {

      const mapsApi       = window.google.maps;
      const algeria       = { lat: 27.929864, lng: 2.573023};
      
      const mapOptions = {
        center				      : algeria,
        mapTypeId			      : mapsApi.MapTypeId.ROADMAP,
        mapTypeControl		  : false,
        navigationControl	  : false,
        streetViewControl	  : false,
        disableDefaultUI	  : true,
        clickableIcons      : false,
        panControl			    : false,
        zoomControl			    : false,
        scaleControl        : false,
        zoom			      	  : initZoom,
        minZoom				      : minZoom,
        maxZoom				      : maxZoom,
        controlSize         : 25,
        zoomControlOptions	: {
            style			  : mapsApi.ZoomControlStyle.DEFAULT,
            position		: mapsApi.ControlPosition.RIGHT_BOTTOM
        },
        styles              : [
            {
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#f5f5f5"
                }
              ]
            },
            {
              "elementType": "labels",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#ff0000"
                }
              ]
            },
            {
              "elementType": "labels.text.stroke",
              "stylers": [
                {
                  "color": "#ff0000"
                }
              ]
            },
            {
              "featureType": "administrative",
              "elementType": "geometry",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "administrative.land_parcel",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "administrative.land_parcel",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#ff0000"
                }
              ]
            },
            {
              "featureType": "administrative.neighborhood",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#ff0000"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#ff0000"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#ff0000"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#ff0000"
                }
              ]
            },
            {
              "featureType": "road",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#ff0000"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road.arterial",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#ff0000"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#ff0000"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#ff0000"
                }
              ]
            },
            {
              "featureType": "road.local",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#ff0000"
                }
              ]
            },
            {
              "featureType": "transit",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "transit.line",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#ff0000"
                }
              ]
            },
            {
              "featureType": "transit.station",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#ff0000"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#cdd8de"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#ff0000"
                }
              ]
            }
        ]
      };
      
      // Create map instance.
      const newMap  = new mapsApi.Map(refMap.current, mapOptions);
      const overlay = new mapsApi.OverlayView();
      
      // Add a marker layer for custom markers.
      overlay.draw = function () {
        
        this.getPanes().markerLayer.id = 'marker-layer';
        
      }
      overlay.setMap(newMap);
      
      // Add day/night overlay.
      // http://js-tutorial.com/day-night-overlay-357
      // https://github.com/marmat/google-maps-api-addons
      if ( typeof window.DayNightOverlay !== 'undefined' && 
           window.DayNightOverlay !== null ) {
        
        const sunShadow = new window.DayNightOverlay({ 
          map: newMap,
          fillColor: 'rgba(0, 0, 0, 0.2)'
        });
      
      }

      // Reference google map.
      setGMap(newMap);

    }
    
    const addEventsToMap = () => {
      
      // Check gMap has been set.
      if ( gMap === null ) return;
      
      gMap.addListener('bounds_changed', () => {
        
        // Check we have some mapBounds to compare.
        if ( mapBounds.current === null ) return;
        
        // Check if bounds really changed, when at the limit of zooming 
        // in or out, bounds chnaged is fired even when bounds haven't changed.
        const newNeLat = gMap.getBounds().getNorthEast().lat();
        const newNeLng = gMap.getBounds().getNorthEast().lng();
        const newSwLat = gMap.getBounds().getSouthWest().lat();
        const newSwLng = gMap.getBounds().getSouthWest().lng();
        
        const curNeLat = mapBounds.current.getNorthEast().lat();
        const curNeLng = mapBounds.current.getNorthEast().lng();
        const curSwLat = mapBounds.current.getSouthWest().lat();
        const curSwLng = mapBounds.current.getSouthWest().lng();
        
        if ( newNeLat !== curNeLat &&
             newNeLng !== curNeLng &&
             newSwLat !== curSwLat &&
             newSwLng !== curSwLng ) boundsChanged.current = true;
        else boundsChanged.current = false;
        
      });
      
      gMap.addListener('idle', () => {
        
        // Get & store new map bounds.
        mapBounds.current = gMap.getBounds();
        
        // Refresh the map to trigger marker fetch.
        if ( boundsChanged.current ) {
          
          // Stop the map animating.
          setIsMapAnimating(false);
          
          // Set refreshMap to trigger a data fetch.
          setRefreshMap(true);
          
          // Set map key messags on zoom.
          setMapKeyMsgs();
          
        }
        
        // Start initial animation.
        if ( !doFirstRun ) setDoFirstRun(true);
        
      });
      
    }
    
    const renderMarkers = (mkrArr) => {
      
      const mapsApi     = window.google.maps;
      const newMkrArr   = mkrArr !== null ? [ ...mkrArr ] : [ ...resultsMapArr ];
      
      let tmpMkrArr = [];
      let pinImg    = null;
      
      // Update the map zoom key icon, students/educators. 
      if ( curSearchFilter.current === 1024 ) setIsMapKeyStudent(true);
      else setIsMapKeyStudent(false);

      // Remove old markers from the map &
      // set markers array to an empty aray.
      if ( markersArr.current.length > 0 ) {
        
        for ( let i = 0; i < markersArr.current.length; i++ ) markersArr.current[i].setMap(null);
        
        markersArr.current = [];

      }
      
      // Define pin & how it is chosen.
      if ( mkrArr !== null ) pinImg = curAnimateObj.current.searchFilter === 1024 ? 'img/student_pin.png' : 'img/educator_pin.png';
      else pinImg = mapSelectorOption.searchFilter === 1024 ? 'img/student_pin.png' : 'img/educator_pin.png';

      if ( newMkrArr.length > 0 ) {
        
        // Define pin.
        let pin = null;
        
        // Set relevant students/educators pin.
        const pinIcon = {
          url         : pinImg,
          size        : new mapsApi.Size(10, 20),
          scaledSize  : new mapsApi.Size(10, 20),
          origin      : new mapsApi.Point(0, 0),
          anchor      : new mapsApi.Point(5, 20)
        };
      
        // Add new markers to the map.
        newMkrArr.map((marker, idx) => {
          
          pin = new mapsApi.Marker({
            map       : gMap,
            position  : {
              lat: marker.lat,
              lng: marker.lng
            },
            icon      : pinIcon,
            optimized : false
          });
          
          // Add click event to the pin, will display results in list view.
          // Don't add click event to student pins in PREVIEW/GUEST mode.
          if ( isGuest ) {
            
            if ( marker.typ !== 1024 ) pin.addListener('click', () => onClickPinSearch(marker.cid));
            
          }
          else pin.addListener('click', () => onClickPinSearch(marker.cid));
          
          tmpMkrArr.push(pin);
        
        });

        // Set markers array.
        markersArr.current = [ ...tmpMkrArr ];
        
        if ( isMapAnimating ) setCurAnimateName(curAnimateObj.current.name);
      
      }

      // Store students & educators markers in their arrays,
      // cycle between these marker arrays if bounds don't change.
      if ( curSearchFilter.current === 1024 && curTagUNID.current === 0 ) studentsResultsArr = [ ...newMkrArr ];
      else if ( mapSelectorOption.searchFilter === 1 ) educatorsResultsArr = [ ...newMkrArr ];
      
      // Includes markers fetching & markers rendering.
      setMarkersLoading(false);
      
      setRefreshMap(false);

    }
    
    const renderStoredMarkers = (searchFilter, tagUNID) => {
      
      if ( searchFilter === 1024 ) renderMarkers([ ...studentsResultsArr ]);
      else if ( searchFilter === 1 ) renderMarkers([ ...educatorsResultsArr ]);
      else renderMarkers(null);
      
    }
    
    const onClickPinSearch = cid => {
      
      // Stop the map animating.
      setIsMapAnimating(false);
      
      // If the map is currently showing 'All Learning Partners' markers.
      if ( mapSelectorOption.searchFilter === 1 ) {
        
        const optionArr = educatorsFilterArr.filter(option => option.Tag === 'ALP');
        
        // Add the cid, to return correct user/users from server.
        optionArr[0].cid = cid;
        
        onClickSelectorOption(optionArr[0], 'educators', 'map');
        
      }
      
      // Else if the map is showing 'All Students' or Students Exams markers.
      else {
        
        const curSelector = studentsFilterArr.filter(option => option.UNID === mapSelectorOption.tagUNID);
        
        // Add the cid, to return correct user/users from server.
        mapSelectorOption.cid = cid;
        
        setRefCurStudentsOption(curSelector[0]);
        onClickSelectorOption(mapSelectorOption, 'students', 'map');
        
      }
      
    }
    
    const setMapToUserPos = () => {
      
      // Stop the map animating.
      setIsMapAnimating(false);
      
      if ( gMap.getZoom() < 5 ) gMap.setZoom(5);
      gMap.panTo(userCoords);
      
      // Update 'refreshMap' to force a marker fetch.
      setRefreshMap(true);
                
    }
    
    const onClickNavigateTo = path => {
        
        // Reset Community tag.
        setCommunityQuery(null);
        
        // Navigate to the path using history push.
        history.push('/' + path);
        
    }
    
    const getInitialServerData = () => {

      // Get search filter data from the server.
      getSearchFilterData();
      
      // Fetch initial results for STUDENTS.
      fetchListData('students', 1024, 0, null);
      
      // Fetch initial results for EDUCATORS.
      fetchListData('educators', 1, null, null);
        
    }
    
    //let circle = null;
    const computeRadius = () => {
      
      let radius    = 6371000;
      
      const bounds  = gMap.getBounds();
      const center  = gMap.getCenter();

      if ( typeof bounds !== 'undefined' && bounds !== null && typeof center !== 'undefined' && center !== null ) {
        
        const ne = bounds.getNorthEast();
        
        // Calculate radius (in meters).
        radius = window.google.maps.geometry.spherical.computeDistanceBetween(center, ne);	
		
      }
      
      // if ( circle !== null ) {
        
      //   circle.setMap(null);
      //   circle = null;
        
      // }
      
      // circle = new window.google.maps.Circle({
      //   strokeColor: '#FF0000',
      //   strokeOpacity: 0.8,
      //   strokeWeight: 2,
      //   fillColor: '#FF0000',
      //   fillOpacity: 0.35,
      //   map: gMap,
      //   center: center,
      //   radius: radius
      // });
      
      return radius; 
                 
    }
    
    const fetchMapData = (searchFilter, tagUNID) => {
      
      // Check gMap has been set.
      if ( gMap === null ) return;
      
      // Set current vars for updating map zoom key & animating arrays.
      curSearchFilter.current = searchFilter;
      curTagUNID.current = tagUNID;
      
      // Get map center point.
      const mapCoords = gMap.getCenter();
       
      // Define query string params.
      let qParams = {
        nelat           : mapBounds.current.getNorthEast().lat(),
        neLng           : mapBounds.current.getNorthEast().lng(),
        swLat           : mapBounds.current.getSouthWest().lat(),
        swLng           : mapBounds.current.getSouthWest().lng(),
        mapRadius       : computeRadius(),
        mapCenterLat    : mapCoords.lat(),
        mapCenterLng    : mapCoords.lng(),
        userLat         : userCoords.lat,
        userLng         : userCoords.lng, 
        tagUNID         : tagUNID,
        searchFilter    : searchFilter,
        searchType      : 0
      };
      
      // Update markers fetching state.
      setIsMarkersFetching(true);
      
      // Includes markers fetching & markers rendering.
      setMarkersLoading(true);
      
      // Fetch marker results.
      getMarkersData(qParams);

    }
    
    const fetchListData = (type, searchFilter, tagUNID, cid) => {
      
      if ( type === 'students' ) setIsStudentsFetching(true);
      else if ( type === 'educators' ) setIsEducatorsFetching(true);
      
      // Define query string params.
      let qParams = {
        nelat           : 0.000000,
        neLng           : 0.000000,
        swLat           : 0.000000,
        swLng           : 0.000000, 
        mapRadius       : 100000,
        mapCenterLat    : 0.000000,
        mapCenterLng    : 0.000000,
        userLat         : 0.000000,
        userLng         : 0.000000, 
        cid             : cid,
        tagUNID         : tagUNID,
        searchFilter    : searchFilter,
        searchType      : type === 'students' ? 1 : 2,
        searchName      : type === 'students' ? inpSearchStudents : inpSearchEducators
      };
      
      // If browser supports geolocation.
      if ( navigator.geolocation ) {
          
        navigator.geolocation.getCurrentPosition(curPos => {
            
          // Update coords with user location.
          qParams.mapCenterLat    = curPos.coords.latitude;
          qParams.mapCenterLng    = curPos.coords.longitude;
          qParams.userLat         = curPos.coords.latitude;
          qParams.userLng         = curPos.coords.longitude;
          
          getDiscoverData(qParams);
            
        },
        error => {
            
          console.log('Map location error: ', error);
          getDiscoverData(qParams);
            
        },
        {
          enableHighAccuracy  : true,
          maximumAge          : 60000,
          timeout             : 10000
        });
          
      }
      
      // Else use a default global location.
      else getDiscoverData(qParams);

    }
    
    const onClickListSearch = type => {
        
        // Fetch STUDENTS data.
        if ( type === 'students' ) {
            
            if ( isStudentsFetching ) return false;
            
            if ( inpStudentsGo ) {
                
                setInpStudentsGo(false);
                fetchListData(type, 1024, selectorStudentsOption.UNID, null);
                
            }
            else {
                
                setInpStudentsGo(true);
                setInpSearchStudents('');
                
                refInpStudents.current.focus();
                
            }
            
        }
        
        // Fetch EDUCATORS data.
        else if ( type === 'educators' ) {
            
            if ( isEducatorsFetching ) return false;
            
            if ( inpEducatorsGo ) {
                
                setInpEducatorsGo(false);
                fetchListData(type, selectorEducatorsOption.UNID, null, null);
                
            }
            else {
                
                setInpEducatorsGo(true);
                setInpSearchEducators('');
                
                refInpEducators.current.focus();
                
                
            }
            
        }
  
    }
    
    const onClickSelectorOption = (option, type, triggeredBy) => {
      
      // Check if we have a cid, else set cid to null.
      const cid = option.cid ? option.cid : null;
      
      if ( type === 'students' ) {
          
          if ( isStudentsFetching ) return false;
          
          if ( triggeredBy === 'list' ) {
            
            setRefCurStudentsOption(option);
            fetchListData(type, 1024, option.UNID, cid);
            
          }
          else {
            
            fetchListData(type, 1024, option.tagUNID, cid);
            
          }

      }
      else if ( type === 'educators' ) {
        
          if ( isEducatorsFetching ) return false;
          
          setRefCurEducatorsOption(option);
          fetchListData(type, option.UNID, null, cid); 

      }
        
    }
    
    const onClickToggleSelector = (type) => {

        if ( type === 'students' ) setShowStudentsSelector(!showStudentsSelector);
        else if ( type === 'educators' ) setShowEducatorsSelector(!showEducatorsSelector);
        
    }
    
    const onKeyupList = ( event, type ) => {
        
        if ( type === 'students' ) {
            
            if ( isStudentsFetching ) return false;
            
            if ( event.key === 'Enter' ) {
                
                setInpStudentsGo(false);
                fetchListData(type, 1024, selectorStudentsOption.UNID, null);
                
            }
            else if ( event.key === 'Backspace' ) setInpStudentsGo(true);

        }
        else if ( type === 'educators' ) {
            
            if ( isEducatorsFetching ) return false;
            
            if ( event.key === 'Enter' ) {
                
                setInpEducatorsGo(false);
                fetchListData(type, selectorEducatorsOption.UNID, null, null);
                
            }
            else if ( event.key === 'Backspace' ) setInpEducatorsGo(true);
 
        }
        
    }
    
    const showMoreResults = type => {
        
        if ( type === 'students' ) {
            
            const nextStudents = studentsListPoolArr.splice(0, 4);
            setStudentsListArr([ ...studentsListArr, ...nextStudents ]);
            
            if ( studentsListPoolArr.length < 1 ) setIsMoreStudents(false);
            
        }
        else if ( type === 'educators' ) {
            
            const nextEducators = educatorsListPoolArr.splice(0, 4);
            setEducatorsListArr([ ...educatorsListArr, ...nextEducators ]);
            
            if ( educatorsListPoolArr.length < 1 ) setIsMoreEducators(false);
            
        }
        
    }
    
    // Return the tags acronym using the 'tagLookupUNID'.
    const getTagFromId = id => {
        
        const item = user.tags.filter(item => item.UNID === id);
        
        return item[0].tag;
        
    }
    
    const renderTagList = (item) => {
        
        // If this student has tags.
        if ( item.userTags && item.userTags.length > 0 ) {
            
            return (
                item.userTags.map((tag, idx) => {
                    
                    const tagName = getTagFromId(tag.tagLookupUNID);
                    
                    return ( <li key={idx}>
                                <button onClick={tag => onClickCommunityWithTag(tagName)}>#{tagName}</button>
                            </li>
                    );
                })
            );
            
        }
        // Else student has no tags.
        else return;

    }
    
    // Navigate to the Community with a defined tag.
    const onClickCommunityWithTag = tag => {
        
        // Set tag.
        setCommunityQuery('/tag?et=' + tag);
        
        // Navigate to the Community/Forum page using history push.
        history.push('/community/forum');
        
    }
    
    // Navigate to the Comunity with students name.
    const onClickCommunityWithName = name => {
        
        // Replace spaces with '+'.
        const parsedName = name.replace(/\s+/g, '+');
        
        // Set name.
        setCommunityQuery('/user/' + parsedName);
        
        // Navigate to the Community/Forum page using history push.
        history.push('/community/forum');
        
    }
    
    const showEducatorStatus = item => {
        
        if ( item.typeStatus && item.typeStatus === 1 ) {
            
            return <div className="result-partner txt-gold">Gold Approved Learning Partner</div>;
            
        }
        else if ( item.typeStatus && item.typeStatus === 2 ) {
            
            return <div className="result-partner txt-platinum">Platinum Approved Learning Partner</div>;
            
        }
        else if ( item.typeStatus && item.typeStatus === 4 ) {
            
            return <div className="result-partner txt-silver">Silver Approved Learning Partner</div>;
          
        }
        else return;
        
    }
    
    const openExtLink = (url, newTab) => {
        
        if ( newTab ) window.open(url);
        else window.location.assign(url);
        
    }
    
    const onClickMapToggleSelector = () => {
        
      setShowMapSelector(!showMapSelector);
      
    }
    
    const onClickMapSelectorOption = (option) => {
      
      // Re-allow fetches, stopped when map is animating.
      setAllowSelectorFetch(true);
      
      // Stop the map animating.
      setIsMapAnimating(false);
        
      // Call useState method to update user tag aray.
      setMapSelectorOption(option);
      
      // Hide the selector drop down.
      onClickMapToggleSelector();
      
      // If exam selected, Set date to next exam.
      if ( option.tagUNID !== null && option.tagUNID !== 0 ) {
        
        const today     = new Date();
        
        // Split exam date into an array.
        const exDtArr   = option.date.split('-');
        
        // Reformat the date from UK into US format.
        const examDate  = new Date(exDtArr[1] + '-' + exDtArr[0] + '-' + exDtArr[2]);
        
        // Update next exams message.
        if ( examDate.getTime() <= today.getTime() ) setNextExamDate('');
        else {
          
          let diffTime    = (examDate.getTime() - today.getTime()) / 1000;
        
          diffTime /= (60 * 60 * 24 * 7);

          const numWeeks  = Math.abs(Math.round(diffTime));
          const txtWeek   = numWeeks > 1 ? 'weeks' : 'week';
          
          if ( numWeeks === 0 ) setNextExamDate(' - Next exam this week');
          else setNextExamDate(` - Next exam in ${numWeeks} ${txtWeek}`);
            
          }

      }
      else setNextExamDate('');
      

    }
    
    const updateMapSelectorArray = () => {
      
      const examsArr = user.tags.map(item => {
        
        return {
          name          : 'Students tagged #' + item.tag,
          searchFilter  : 1024,
          tagUNID       : item.UNID,
          date          : item.tagdate
        };
        
      });
      
      const tmpArr = [
        ...animationArr,
        ...examsArr,
      ];
      
      setMapSelectorArr([...tmpArr]);
      
    }
    
    const animateMarkers = () => {
      
      // Check gMap has been set.
      if ( gMap === null ) return;

      // Educators should animate for a shorter period than students.
      let delay = studentsDelay;
      
      if ( animateIndex.current === 0 ) setCurAnimateName(animationArr[1].name);
      else setCurAnimateName(animationArr[0].name);

      function updateMarkers () {
        
        let idx = animateIndex.current === 0 ? 1 : 0;

        // Update animation index for next pass.
        animateIndex.current = idx;
        
        // Update animation delay variable. 
        if ( idx === 1 ) delay = educatorsDelay;
        else delay = studentsDelay;
        
        curAnimateObj.current = animationArr[idx]; 
        
        setMapSelectorOption(animationArr[idx]);
        
        // Set current vars for updating map zoom key & animating arrays.
        curSearchFilter.current = animationArr[idx].searchFilter;
        curTagUNID.current = animationArr[idx].tagUNID;

        // Define whether we have stored markers set to false initially.
        let hasStoredMarkers = false;

        // Do we have stored educators markers?
        if ( animationArr[idx].searchFilter === 1024 && studentsResultsArr.length > 0 ) hasStoredMarkers = true;
        
        // Do we have stored educators markers?
        if ( animationArr[idx].searchFilter === 1 && educatorsResultsArr.length > 0 ) hasStoredMarkers = true;
        
        if ( !boundsChanged.current && hasStoredMarkers ) renderStoredMarkers(animationArr[idx].searchFilter, animationArr[idx].tagUNID);
        else {
          
          // Update markers fetching state.
          setIsMarkersFetching(true);
          
          // Fetch map marker results.
          fetchMapData(animationArr[idx].searchFilter, animationArr[idx].tagUNID);
          
        }
 
        // Start new lopop.
        animateInterval.current = setTimeout(updateMarkers, delay * 1000);
        
      }
      updateMarkers();
      
    }
    
    const setMapKeyMsgs = () => {
      
      // Get current map zoom level.
      const zoom = gMap.getZoom();
      
      // Update map key messages.
      if ( zoom === 10 || zoom === 9 || zoom === 8 || zoom === 7 ) {
          
        setZoomLevelStudentsMsg('Students');
        setZoomLevelEducatorsMsg('Learning Partners');
        
      }
      else if ( zoom === 6 || zoom === 5 ) {
        
        setZoomLevelStudentsMsg('Local spotlights');
        setZoomLevelEducatorsMsg('Local spotlights');
        
      }
      else if ( zoom === 4 || zoom === 3 ) {
        
        setZoomLevelStudentsMsg('Country spotlights');
        setZoomLevelEducatorsMsg('Country spotlights');
        
      }
      else {
        
        setZoomLevelStudentsMsg('Global spotlights');
        setZoomLevelEducatorsMsg('Global spotlights');
        
      }
      
    }
    
    const onClickAnimateMap = () => {
      
      boundsChanged.current = false;
      
      studentsResultsArr = [];
      educatorsResultsArr = [];
      
      setNextExamDate('');
      
      setIsMapAnimating(!isMapAnimating)
      
    }
    
    const onClickCloudPilot = () => {
     
      // Display OLP resuts in list view.
      const optionArr = educatorsFilterArr.filter(option => option.Tag === 'OLP');
      
      onClickSelectorOption(optionArr[0], 'educators', 'map');
      
    }
    
    const initiateComponent = () => {
      
      // Check that the Google Maps API has loaded.
      if ( typeof window.google === 'object' &&
            typeof window.google.maps === 'object' ) {
              
        // Set whether the browser support geolocation.
        if ( navigator.geolocation ) {
            
          navigator.geolocation.getCurrentPosition(curPos => {
              
              setUserCoords({
                  lat: curPos.coords.latitude,
                  lng: curPos.coords.longitude
              });
              
              // Render the map, user has location data.
              renderMap();
              
          },
          error => {
              
              console.log('Map location error: ', error);
              
              // If the user has denied allowing browser location
              // then render map using default coords (London).
              renderMap();
              
          });

        }
        // If the browser doesn't support geolocation then render the map
        // using default coords (London).
        else renderMap();
            
      }
      
      // Populate all local states with server data.
      getInitialServerData();
      
      // Set placeholder text.
      setInpStudentsPlaceholder('Search for students or select a filter');
      setInpEducatorsPlaceholder('Search ALPs or select a filter');
      
      // Set the index to start map animating from, if 0 will show 1.
      // As the animation toggles between 'ALl Students' & 'All Educators'.
      //animateIndex.current = 1;
      
      // Update zoom key messages.
      setZoomLevelStudentsMsg('Global spotlights');
      setZoomLevelEducatorsMsg('Global spotlights');
      
    }
    
    const onClickLogin = () => {
      
      // Open a login modal through the context api.
      toggleLoginModal(true);
      
    }
  
    const onClickRegister = () => {
      
      // Navigate to 'home'.
      history.push('/');
      
      // Open a register modal through the context api.
      timer05 = setTimeout(() => toggleRegisterModal(true), 500);
      
    }
    

    
    /*
     * Component hooks.
     */
    
    useEffect(() => {
      
      // If PREVIEW mode & user is logged out then we need to fetch a GUEST context using a guest token.
      if ( isPreview && !isAuthenticated ) {
        
        isGuest = true;
        
        // If in PREVIEW mode then we should start animating with Learning Partners not students.
        // By setting the index to 0 it gets flipped in the code and we will get 1/Learning Partners first.
        animateIndex.current = 0;
        
        // Set context api to discoverPreview, true.
        setDiscoverPreview(true);
        
        // Call API user with a guest token.
        callApiGuestUser(guestToken);
        
      }
      
      // Else create the page as a logged in user.
      else initiateComponent();

      // Clean up event listeners.
      return () => {
        
        // Clear external component arrays.
        studentsResultsArr        = [];
        educatorsResultsArr       = [];
        
        // Clear interval loop.
        clearTimeout(animateInterval.current);
        
        // Clear any timers.
        clearTimeout(timer01);
        clearTimeout(timer02);
        clearTimeout(timer03);
        clearTimeout(timer04);
        
        // Tell Context API that this component is now unmounted.
        nowUnmountDiscover(true);
          
      }
      
    // The eslint comment below removes missing dependency warning from console.
    // eslint-disable-next-line
    }, []);
    
    useEffect(() => {
      
      if ( user !== null && user.FN === 'Guest' ) {
        
        initiateComponent();
        
      }
      
    }, [user]);
    
    useEffect(() => {
      
      if ( isAuthenticated ) {
        
        isGuest = false;
        
        // Set context api to discoverPreview, true.
        setDiscoverPreview(false);
        
      }

    }, [isAuthenticated]);
    
    useEffect(() => {
     
      // Check gMap has been set.
      if ( gMap === null ) return;
      
      // Add map event handlers.
      addEventsToMap();

      // Show brand screen.
      setIsBrandReady(true);
      
    }, [gMap]);
    
    useEffect(() => {
      
      // Refresh the map to trigger marker fetch.
      if ( doFirstRun ) setIsMapAnimating(true);
      
      // Trigger cloud pilots journey.
      timer04 = setTimeout(() => setSailOn(true), 15000);
      
    }, [doFirstRun]);
    
    useEffect(() => {
      
        // Splice 'Students' item from 'searchFilterData' array, the UNID of this
        // will be used in the API request. Only run code once by checking educators option is null.
        if ( searchFilterData !== null && selectorEducatorsOption === null ) {
        
            const studentIdx = searchFilterData.findIndex(item => item.Title === 'Student');
            
            if ( studentIdx !== -1 ) {
                
                searchFilterData.splice(studentIdx, 1);
                
            }
            
            // Populate EDUCATORS filter array from 'searchFilterData' array.
            setEducatorsFilterArr([ ...searchFilterData ]);
            
            // Set initial EDUCATORS selector option.
            if ( searchFilterData.length ) {
                
                setRefCurEducatorsOption(searchFilterData[0]);
                setSelectorEducatorsOption(searchFilterData[0]);
                
            }
            
            // Update map selector array.
            updateMapSelectorArray();

        }
        
        // If we have a user context & the students option is still null (else this will run on every refresh).
        if ( user !== null && selectorStudentsOption === null )  {
            
            // Populate STUDENTS filter array from the tags list in the users api.
            if ( user.tags !== null ) setStudentsFilterArr([ ...studentsFilterArr, ...user.tags ]);
            
            // Set initial STUDENTS selector option.
            if ( studentsFilterArr.length ) {
              
                setRefCurStudentsOption(studentsFilterArr[0]);
                setSelectorStudentsOption(studentsFilterArr[0]);
                
            }
            
        }
 
    }, [searchFilterData, user]);
    
    useEffect(() => {
      
      if ( studentsData !== null && isStudentsFetching === true ) {
       
        setIsStudentsFetching(false);
        setSelectorStudentsOption(refCurStudentsOption);
        
        if ( inpSearchStudents === '' ) setInpStudentsGo(true);
        
        // Put first 4 students into current array and following 96 into pool.
        setStudentsListArr(studentsData.slice(0, numGetresults));
        setStudentsListPoolArr(studentsData.slice(numGetresults, numMaxResults));
        
        // Re-enable 'More' button. 
        setIsMoreStudents(true);
        
      }
      
      if ( errorStudents !== null ) setIsStudentsFetching(false);
      
    }, [studentsData, errorStudents]);
    
    useEffect(() => {
      
      if ( educatorsData !== null && isEducatorsFetching === true ) {

        setIsEducatorsFetching(false);
        setSelectorEducatorsOption(refCurEducatorsOption);
        
        if ( inpSearchEducators === '' ) setInpEducatorsGo(true);
        
        // Put first 4 educators into current array and following 96 into pool.
        setEducatorsListArr(educatorsData.slice(0, numGetresults));
        setEducatorsListPoolArr(educatorsData.slice(numGetresults, numMaxResults));
        
        // Re-enable 'More' button. 
        setIsMoreEducators(true);
        
      }
      
      if ( errorEducators !== null ) setIsEducatorsFetching(false);
      
    }, [educatorsData, errorEducators]);
    
    useEffect(() => {
    
      if ( markersData !== null && isMarkersFetching === true ) {
        
        // Populate the results map array with fetched server data.
        setResultsMapArr([ ...markersData ]); 

      }
      
    }, [markersData, errorMarkers]);
    
    useEffect(() => {
      
      // Check gMap has been set.
      if ( gMap === null ) return;
        
      // Clear the markers data array.
      clearMarkersData();
      
      setIsMarkersFetching(false);
      
      // On subsequent marker fetch...
      if ( isMapReady ) renderMarkers(null);
      
      // First marker fetch after branding screen.
      else {

        timer01 = setTimeout(() => {
          
          timer02 = setTimeout(() => renderMarkers(null), 600);
          setZoomQuadOut(true);
          setIsBrandReady(false);
          timer03 = setTimeout(() => setIsMapReady(true), 1000);
          
          gMap.setOptions({ zoomControl: true });
          
        }, brandDelay * 1000);
        
      }
      
    }, [resultsMapArr]);
    
    useEffect(() => {
     
      if ( allowSelectorFetch ) fetchMapData(mapSelectorOption.searchFilter, mapSelectorOption.tagUNID);
      
    }, [mapSelectorOption]);

    useEffect(() => {

      if ( isMapAnimating ) {
        
        setAllowSelectorFetch(false);
        
        animateMarkers();
        
      }
      else {
        
        // Stop animation by clearing setInterval.
        clearTimeout(animateInterval.current);
        
      }
      
    }, [isMapAnimating]);
    
    useEffect(() => {
      
      if ( inpSearchStudents === '' &&
           selectorStudentsOption !== null ) fetchListData('students', 1024, selectorStudentsOption.UNID, null);
      
    }, [inpSearchStudents]);
    
    useEffect(() => {
      
      if ( inpSearchEducators === '' &&
           selectorEducatorsOption !== null ) fetchListData('educators', selectorEducatorsOption.UNID, null, null);
      
    }, [inpSearchEducators]);
    
    useEffect(() => {
      
      if ( refreshMap ) fetchMapData(mapSelectorOption.searchFilter, mapSelectorOption.tagUNID);
      
    }, [refreshMap]);
    
    
    
    
    /*
     * JSX elements.
     */
  
    const drawBtnUserCntr = (
      <button className={'btn-user-position' + ( isMapReady ? ' show' : '' )}
              onClick={setMapToUserPos}>
              <i className="icon-map-target" />
      </button>
    );  
    
    const drawMapSelector = (
      <div className={'map-selector' + ( isMapReady ? ' show' : '' )}>
        <div className="form-element-wrapper mgn-btm-0">
          <div className="selector-wrapper">
              <div className="selector-static">
                  <button className="btn-selector map"
                          onClick={onClickMapToggleSelector}>
                    { isMapAnimating ? curAnimateName : mapSelectorOption.name }
                  </button>
              </div>
              <div className={'selector-dynamic' + ( showMapSelector ? ' show' : '' )}>
                  <div className="selector-scroller">
                      { mapSelectorArr.length < 1 
                      ?
                      <ul>
                          <li>There are no tags to select</li>
                      </ul>
                      : 
                      <ul>
                          {mapSelectorArr.map((option, idx) => {
                              return (
                                  <li key={idx}><button onClick={() => onClickMapSelectorOption(option)}>{option.name}</button></li>
                              );
                          })}
                      </ul>
                      }
                  </div>
              </div>
          </div>
        </div>
      </div>
    );
    
    const drawBtnAnimate = (
      <button className={'btn-map-animate' + ( isMapReady ? ' show' : '' )}
              onClick={() => onClickAnimateMap()}>
              { isMapAnimating ?
              <i className="icon-pause" />
              :
              <i className="icon-play" />
              }
      </button>
    );
    
    const drawZoomKey = (
      <div className={'zoom-key' + ( isMapReady ? ' show' : '' )}>
        <div>
          { markersLoading ?
            <>
              <i className="icon-spinner-1 txt-dark"></i>
              { isMapKeyStudent ? zoomLevelStudentsMsg + nextExamDate : zoomLevelEducatorsMsg }
            </>
          :
            <>
              <img src={studentsKeyPin} className={ isMapKeyStudent ? 'show' : '' } alt="" />
              <img src={educatorsKeyPin} className={ isMapKeyStudent ? '' : 'show' } alt="" />
              { isMapKeyStudent ? zoomLevelStudentsMsg + nextExamDate : zoomLevelEducatorsMsg }
            </>
          }
          
        </div>
      </div>
    );
    
    const drawBrandScreen = (
      <div className="brand-screen-wrapper">
        <div className={'brand-screen' + ( isBrandReady ? ' show' : '' )}>
          <div className="brand-title">Discover the community</div>
          <div className={'brand-quad' + ( zoomQuadOut ? ' zoom-out' : '' )}></div>
          <div className="brand-subtitle-wrapper">
            <div className="brand-subtitle">A world of ACCA students and learning providers</div>
          </div>
        </div>
      </div>
    );
    
    const drawCloudPilot = (
      <button className={'cloud-pilot' + ( sailOn ? ' sail-on' : '' )} onClick={onClickCloudPilot}>
        <img srcSet="img/olp_cloud.png 1x, img/olp_cloud@2x.png 2x" 
             src="img/olp_cloud.png" 
             alt="" />
      </button>
    );
    
  
    
    
    
    /*
     * Component view.
     */
    return (
        <div className="pages discover" onClick={event => onClickAppPage(event)}>
            <div className="fixed-width">
              <div className={'discover-map' + ( isMapReady ? ' show' : '' )}>
                <div className="discover-map-container" ref={refMap}></div>
                {drawBtnUserCntr}
                {drawMapSelector}
                {drawBtnAnimate}
                {drawZoomKey}
                { !isMapReady && drawBrandScreen }
                <div className="cloud-pilots-wrapper">
                  <div className="cloud-pilots">
                    {drawCloudPilot}
                  </div>
                </div>
              </div>
                <div className="discover-list">
                  { !isGuest &&
                  <div className="lists-header">
                      <p className="txt-type-1">
                          Add exam tags to <button className="btn-style-link txt-red" 
                                            onClick={() => onClickNavigateTo('settings')}>your profile</button> and use the map, or search below, to discover the community.
                      </p>
                  </div>
                  }
                  <div className="lists-wrapper">
                      <div className="list-students">
                          
                          <h4>Discover Students</h4>
                          
                          { isGuest ?
                            <>
                              <p className="txt-type-1">
                                <button className="btn-style-link txt-red" 
                                        onClick={onClickLogin}>Login</button> or <button className="btn-style-link txt-red" 
                                                                                         onClick={onClickRegister}>Register</button> to get full access to the ACCA Learning Community
                            </p>
                            </>
                            :
                            <>
                              <div className="list-controls">
                                  <div className="inp-btn-wrapper">
                                      <input type="text"
                                              ref={refInpStudents} 
                                              maxLength="72"
                                              value={inpSearchStudents}
                                              onKeyUp={event => onKeyupList(event, 'students')}
                                              onFocus={() => setInpStudentsGo(true)}
                                              onChange={event => setInpSearchStudents(event.target.value)}
                                              placeholder={inpStudentsPlaceholder} />
                                      <button className={ 'btn-tertiary btn-fetch' + ( isStudentsFetching ? ' fetching' : '' ) }
                                              tabIndex="-1" 
                                              onClick={() => onClickListSearch('students')}>
                                          { inpStudentsGo 
                                          ?
                                          <span>Go</span>
                                          :
                                          <i className="icon-cross pad-top-2"></i>
                                          }        
                                      </button>
                                  </div>
                                  <div className="selector-wrapper filter">
                                      <div className="selector-static">
                                          { selectorStudentsOption &&
                                          <button className="btn-selector students"
                                                  onClick={() => onClickToggleSelector('students')}>
                                              { selectorStudentsOption.tag === 'All' || selectorStudentsOption.tag === 'Same' ? selectorStudentsOption.tag : '#' + selectorStudentsOption.tag }
                                          </button>
                                          }
                                      </div>
                                      <div className={'selector-dynamic' + ( showStudentsSelector ? ' show' : '' )}>
                                          <div className="selector-scroller">
                                              { studentsFilterArr.length < 1 
                                              ?
                                              <ul>
                                                  <li><span className="no-results">There are no options to select</span></li>
                                              </ul>
                                              : 
                                              <ul>
                                                  {studentsFilterArr.map((option, idx) => {
                                                      return (
                                                          <li key={idx}><button onClick={() => onClickSelectorOption(option, 'students', 'list')}>{ ( idx !== 0 && idx !== 1 ) ? '#' + option.tag + ' - '  : null}{option.title}</button></li>
                                                      );
                                                  })}
                                              </ul>
                                              }
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              { errorStudents !== null && <div className="fetch-error"><span>{errorStudents}</span></div> }
                              { studentsListArr &&
                              <ul className="results-list">
                                  { studentsListArr.length < 1 &&
                                      <li>No students match your search, please try again.</li>
                                  }
                                  { studentsListArr.map((item, idx) => {
                                      return (
                                          <li key={idx}>
                                              <div className="result-wrapper">
                                                  <div className="col-lft">
                                                      <h2 className="pad-btm-10">{item.ftn}</h2>
                                                      <ul className="box-list row">
                                                          { item.st !== '' &&
                                                          <li>
                                                              <span className="box-item tail max-size-wrap">{item.st}</span>
                                                          </li>
                                                          }
                                                          {renderTagList(item)}
                                                      </ul>
                                                  </div>
                                                  <div className="col-rgt">
                                                      <div className="result-posts"></div>
                                                      <div className="result-country">{item.cy}</div>
                                                      <div className="result-city">{item.tn}</div>
                                                  </div>
                                              </div>
                                          </li>
                                      );
                                  })}
                              </ul>
                              }
                              { studentsListArr && studentsListArr.length > 0 &&
                              <div className="list-footer">
                                  { ( !isMoreStudents && studentsListArr.length >= 100 ) && <div className="more-results-msg">First {numMaxResults} results shown</div> }
                                  <div>
                                      <button className="btn-tertiary"
                                              onClick={() => showMoreResults('students')}
                                              disabled={ !isMoreStudents || studentsListPoolArr.length === 0 }>More</button>
                                  </div>
                              </div>
                              }
                            </>
                          }
                      </div>
                      <div className="list-educators">
                          
                          <h4>Discover Learning Partners</h4>
                          <div className="list-controls"> 
                              <div className="inp-btn-wrapper">
                                  <input type="text"  
                                          ref={refInpEducators}
                                          maxLength="72"
                                          value={inpSearchEducators}
                                          onFocus={() => setInpEducatorsGo(true)}
                                          onKeyUp={event => onKeyupList(event, 'educators')}
                                          onChange={event => setInpSearchEducators(event.target.value)}
                                          placeholder={inpEducatorsPlaceholder} />
                                  <button className={ 'btn-tertiary btn-fetch' + ( isEducatorsFetching ? ' fetching' : '' ) } 
                                          tabIndex="-1" 
                                          onClick={() => onClickListSearch('educators')}>
                                      { inpEducatorsGo 
                                      ?
                                      <span>Go</span>
                                      :
                                      <i className="icon-cross pad-top-2"></i>
                                      }       
                                  </button>
                              </div> 
                              <div className="selector-wrapper filter">
                                  <div className="selector-static">
                                      { selectorEducatorsOption &&
                                      <button className="btn-selector educators"
                                              onClick={() => onClickToggleSelector('educators')}>
                                          {selectorEducatorsOption.Tag}
                                      </button>
                                      }
                                  </div>
                                  <div className={'selector-dynamic' + ( showEducatorsSelector ? ' show' : '' )}>
                                      <div className="selector-scroller">
                                          { educatorsFilterArr.length < 1 
                                          ?
                                          <ul>
                                              <li><span className="no-results">There are no options to select</span></li>
                                          </ul>
                                          : 
                                          <ul>
                                              {educatorsFilterArr.map((option, idx) => {
                                                  return (
                                                      <li key={idx}><button onClick={() => onClickSelectorOption(option, 'educators', 'list')}>{option.Title}</button></li>
                                                  );
                                              })}
                                          </ul>
                                          }
                                      </div>
                                  </div>
                              </div>
                          </div>
                          { errorEducators !== null && <div className="fetch-error"><span>{errorEducators}</span></div> }
                          { educatorsListArr &&
                          <ul className="results-list">
                              { educatorsListArr.length < 1 &&
                                  <li>No Approved Learning Partners match your search, please try again.</li>
                              }
                              { educatorsListArr.map((item, idx) => {
                                  return (
                                      <li key={idx}>
                                          <div className="result-wrapper">
                                              <div className="col-lft">
                                                  <h2 className="pad-btm-10">{item.ftn}</h2>
                                                  {showEducatorStatus(item)}
                                              </div>
                                              <div className="col-rgt">
                                                  <div className="result-posts">
                                                      { item.web &&
                                                      <button className="result-bubble"
                                                              onClick={() => openExtLink(item.web, true)}><i className="icon-linkout"></i></button>
                                                      }
                                                  </div>
                                                  <div className="result-country">{item.cy}</div>
                                                  <div className="result-city">{item.ct}</div>
                                              </div>
                                          </div>
                                      </li>
                                  );
                              })}
                          </ul>
                          }
                          { educatorsListArr && educatorsListArr.length > 0 &&
                          <div className="list-footer">
                          { ( !isMoreEducators && educatorsListArr.length >= 100 ) && <div className="more-results-msg">First {numMaxResults} results shown</div> }
                              <div>
                                  <button className="btn-tertiary"
                                          onClick={() => showMoreResults('educators')}
                                          disabled={ !isMoreEducators || educatorsListPoolArr.length === 0 }>More</button>
                              </div>
                          </div>
                          }
                      </div>
                  </div>
                </div>
            </div>
        </div>
    );
    
}

export default Discover;
