// Reference endpoints from environment file.
let webEndPoint;
let communityEndPoint;
let googleMapKey;
let guestToken;

if ( process.env.NODE_ENV !== 'production' ) {
    
    webEndPoint         = process.env.REACT_APP_WEB_ENDPOINT;
    communityEndPoint   = process.env.REACT_APP_COMMUNITY_ENDPOINT;
    googleMapKey        = process.env.REACT_APP_GOOGLE_MAP_KEY;
    guestToken          = process.env.REACT_APP_GUEST_TOKEN;
    
}
else {
    
    // TODO: Properly implement endpoints environent file.
    webEndPoint         = process.env.REACT_APP_WEB_ENDPOINT;
    communityEndPoint   = process.env.REACT_APP_COMMUNITY_ENDPOINT;
    googleMapKey        = process.env.REACT_APP_GOOGLE_MAP_KEY;
    guestToken          = process.env.REACT_APP_GUEST_TOKEN;
    
}

export { webEndPoint, communityEndPoint, googleMapKey, guestToken };
