import React, { useContext } from 'react';

import Footer from '../layout/Footer';
import UserContext from '../../context/user/userContext';


const Help = () => {
    
    // Reference context apis.
    const userContext               = useContext(UserContext);
    
    // Destructure contexts.
    const { toggleMobileNav }       = userContext;
    
    
    /*
     * Component methods.
     */
    
    const onClickMailto = (address) => {
        
        window.location.href = 'mailto:' + address;
        
    }
    
    const onClickAppPage = event => {
        
        // Hide the mobile nav, even if not open.
        toggleMobileNav(false);
        
    }
    
    
    /*
     * Component view.
     */
    return (
        <div className="pages help" onClick={event => onClickAppPage(event)}>
            <div className="fixed-width flex-fill-space pad-top-40 pad-btm-40">
                
                <div className="flex-row-dbl settings-header"> 
                    <h2>Useful information</h2>
                </div>
                
                <div className="text-block">
                    <div className="text-wrapper-responsive">
                        <h2 htmlFor="status-msg">Welcome to the ACCA Learning Community</h2>
                        <p>Here you can connect with fellow students around the world. It's a great place to ask questions about your studies, share tips and support each other during exams.</p>
                        <p>Below are some guidelines on how to get the most out of it.</p>
                    </div>
                </div>
                
                <div className="text-block">
                    <div className="text-wrapper-responsive">
                        <h2 htmlFor="status-msg">How to create and share posts</h2>
                        <p>Before you add a new post, check to see if anyone has already started a thread on the same subject. It's a quick way to find information and you can also add to the discussion.</p>
                        <p>If you can't find what you need, create your own post in the relevant learning channel. Make sure your subject title is as clear as possible, it should be short and easy to understand. Please don’t write in ALL CAPS.</p>
                        <p>You can add general study topic tags to a post, which helps other students search for it in the learning channel.</p>
                        <p>Help attract attention to useful threads by using the voting buttons to the left of all posts.</p>
                    </div>
                </div>
                
                <div className="text-block">
                    <div className="text-wrapper-responsive">
                        <h2 htmlFor="status-msg">Community standards</h2>
                        <p>The Learning Community is here to allow students to engage, solve problems and provide advice. To ensure that it is a safe, enjoyable and valuable resource, there are some rules on how to behave. </p>
                        <ul>
                            <li>
                                <b>Be kind and support each other.</b> Something that seems simple to you might be harder for others to understand – this is a space for them to reach out and ask for help. Please don’t insult or abuse another member of the community. 
                            </li>
                            <li>
                                <b>Stick to relevant topics.</b> Any post which includes inappropriate or offensive content will be removed. This includes:
                                <ul>
                                    <li><b>Advertising.</b> Any type of commercial activity, such as the sale of study materials, is not allowed.</li>
                                    <li><b>Spam and scams.</b> Do not attempt to disrupt the community or share harmful content. Be aware that repetitive or excessive posting is considered spam and will be removed.</li>
                                </ul>
                            </li>
                            <li>
                                <b>Stay safe.</b> Remember that anything you post will be visible to the rest of the community. Avoid sharing sensitive personal details or confidential information about employers and clients. 
                            </li>
                            <li>
                                <b>Help to moderate the community.</b> If you see a post that contains offensive material or incorrect information, please report it. An ACCA Community Manager is on hand to deal with these issues. 
                            </li> 
                        </ul>
                    </div>
                </div>
                
                {/* <div className="text-block">
                    <div className="text-wrapper-responsive">
                        <h2 htmlFor="status-msg">Still have questions?</h2>
                        <p>If you have a question or feedback that can’t be resolved by the community, please email <button className="btn-style-link txt-blue" onClick={() => onClickMailto('community.reports@accaglobal.com')}>community.reports@accaglobal.com</button>.</p>
                    </div>
                </div> */}
                
            </div>
            <Footer />
        </div>
    );
    
}

export default Help;
