import React, { useState, useContext, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import UserContext from '../../context/user/userContext';
import useWindowDimensions from '../hooks/getWindowDimensions';


const Navbar = () => {
    
    // Define components state.
    const [selProtectNav, setSelProtectNav] = useState(null);
    
    // Reference hooks.
    const history                   = useHistory();
    
    // Reference context apis.
    const userContext               = useContext(UserContext);
    
    // Destructure contexts.
    const { toggleMobileNav, 
            mobNavState, 
            toggleLoginModal, 
            toggleRegisterModal, 
            isAuthenticated, 
            logout, 
            navRedirect, 
            doRedirect, 
            redirectTo, 
            setCommunityQuery }     = userContext;
    
    // Get current route info.
    const location                  = useLocation();
    
    // Get window dimensions.
    const { winWidth }              = useWindowDimensions();
    
    
    /*
     * Component methods.
     */
    
    // Method to return current route name.
    const getRouteName = (location) => {

        switch (location.pathname) {
            case '/':
                return 'Home';
            case '/community':
                return 'Community';
            case '/discover':
                return 'Discover';
            case '/settings':
                return 'Settings';
            case '/help':
                return 'Help';
            default:
                return 'Home';
        }
        
    }
    
    // Handle Menu button clicks.
    const onMenuBtnClick = () => {
        
        const newState = !mobNavState;
        
        toggleMobileNav(newState); 
        
    }
    
    // Handle Login button clicks.
    const onLoginBtnClick = (isMobile) => {
        
        if ( isMobile ) onMenuBtnClick();
        
        // Open a login modal through the context api.
        toggleLoginModal(true);
        
    }
    
    // Handle Logout button clicks.
    const onLogoutBtnClick = (isMobile) => {
        
        if ( isMobile ) onMenuBtnClick();
        
        logout();
        
        // Navigate user to logout page.
        window.location = "/home/logout";
        
    }
    
    // Handle Register button clicks.
    const onRegisterBtnClick = (isMobile) => {
        
        if ( isMobile ) onMenuBtnClick();
        
        // Open a login modal through the context api.
        toggleRegisterModal(true);
        
    }
    
    // Handle app navigation clicks.
    const onClickNavigateTo = (path) => {
        
        // Reset Community tag.
        setCommunityQuery(null);
        
        // Navigate using history push.
        history.push('/' + path);
        
        toggleMobileNav(false); 
        
    }
    
    // Before handling navigation check if isAuthenticated.
    const onClickNavigateProtected = (path, isMobile) => {
        
        // Reset Community tag.
        setCommunityQuery(null);
        
        // If user isAuthenticated navigate to page.
        if ( isAuthenticated ) onClickNavigateTo(path);
        
        // Else open Login modal, on login navigate to page.
        else {
            
            navRedirect({ doRedirect: true, redirectTo: path });
            toggleLoginModal(true);
            
        }
        
        if ( isMobile ) toggleMobileNav(false); 
        
    }
    
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        // Highlight protected navlinks.
        switch (location.pathname) {
            case '/community':
                setSelProtectNav('community');
                break;
            case '/discover':
                setSelProtectNav('discover');
                break;
            default:
                setSelProtectNav(null);
        }
        
        // Close mobile menu if user shrinks screen.
        if ( winWidth > 767 ) toggleMobileNav(false);
        
        // If user is authenticated and 'doRedirect'is true,
        // then navigate to preset route and reset context state.
        if ( doRedirect && isAuthenticated ) {
            
            onClickNavigateTo(redirectTo);
            navRedirect({ doRedirect: false, redirectTo: null });
            
        }
        
        // The eslint comment below removes missing dependency warning from console.
        // eslint-disable-next-line
    }, [location, winWidth, doRedirect, isAuthenticated]); 
    
    
    /*
     * Component view.
     */
    return (
        <nav className="navbar">
            <div className="sub-nav">
                <div className="fixed-width">
                    <div className="sub-nav-link">
                        <NavLink exact to="/">ACCA Learning Community</NavLink>
                    </div>
                    <ul>
                        { isAuthenticated && 
                            <li><NavLink exact to="/settings" activeClassName="selected">Settings</NavLink></li> 
                        }
                        <li><NavLink exact to="/help" activeClassName="selected">Help</NavLink></li>
                        <li>
                            { isAuthenticated 
                                ? <button onClick={() => onLogoutBtnClick(false)}>Logout</button> 
                                : <button onClick={() => onLoginBtnClick(false)}>Login</button> 
                            }
                        </li>
                        { !isAuthenticated && 
                            <li><button onClick={() => onRegisterBtnClick(false)}>Register</button></li>
                        }
                    </ul>
                </div>
            </div>
            <div className="mn-nav">
                <div className="fixed-width">
                    <button className="logo-wrapper" onClick={() => onClickNavigateTo('')}>
                        <img srcSet="img/logo-strapline.png 1x, img/logo-strapline@2x.png 2x" 
                             src="img/logo-strapline.png" 
                             alt="ACCA logo" />
                    </button>
                    <ul className="mn-nav-links">
                        <li><NavLink exact to="/" activeClassName="selected"><span>Home</span></NavLink></li>
                        <li><button className={ 'nLink' + ( selProtectNav === 'community' ? ' selected' : '' ) } onClick={() => onClickNavigateProtected('community', false)}><span>Community</span></button></li>
                        <li><button className={ 'nLink' + ( selProtectNav === 'discover' ? ' selected' : '' ) } onClick={() => onClickNavigateProtected('discover', false)}><span>Discover</span></button></li>                      
                    </ul>
                    <button className="btn-burger" onClick={onMenuBtnClick}>
                        <span>{getRouteName(location)}</span>
                        <i className="icon-thumb-btn"></i>
                    </button>
                </div>
            </div>
            <div className={'mob-nav' + ( mobNavState ? ' show' : '' )}>
                <ul>
                    <li><NavLink exact to="/" activeClassName="selected" onClick={onMenuBtnClick}><span>Home</span></NavLink></li>
                    <li><button className={ 'nLink' + ( selProtectNav === 'community' ? ' selected' : '' ) } onClick={() => onClickNavigateProtected('community', true)}><span>Community</span></button></li>
                    <li><button className={ 'nLink' + ( selProtectNav === 'discover' ? ' selected' : '' ) } onClick={() => onClickNavigateProtected('discover', true)}><span>Discover</span></button></li>
                    { isAuthenticated && 
                    <> 
                        <li><NavLink exact to="/settings" activeClassName="selected" onClick={onMenuBtnClick}><span>Settings</span></NavLink></li>
                    </>
                    }   
                    <li><NavLink exact to="/help" activeClassName="selected" onClick={onMenuBtnClick}><span>Help</span></NavLink></li>
                    <li>
                        <div className="footer-btns">
                            { isAuthenticated 
                                ? <button onClick={() => onLogoutBtnClick(true)}>Logout</button> 
                                : <button onClick={() => onLoginBtnClick(true)}>Login</button> 
                            }
                            { !isAuthenticated && 
                                <button onClick={() => onRegisterBtnClick(true)}>Register</button>
                            }
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
    
}

export default Navbar;
