import {
    TOGGLE_LOGIN_MODAL,
    TOGGLE_REGISTER_MODAL,
    TOGGLE_ATTEST,
    TOGGLE_REG_SUCCESS_MSG,
    SET_FETCHING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    USER_SUCCESS,
    USER_ERROR,
    GUEST_USER_SUCCESS,
    GUEST_USER_ERROR,
    NAV_REDIRECT,
    RESET_PWD_SUCCESS,
    RESET_PWD_ERROR,
    RESET_FORGOT_PWD_MSG,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    FORUM_DATA_SUCCESS,
    FORUM_DATA_ERROR,
    UPDATE_USER_SUCCESS,
    UPDATE_EMAIL_ERROR,  
    UPDATE_STATUS_ERROR,
    UPDATE_TAG_ERROR,
    UPDATE_CHKBOX_ERROR,
    UPDATE_PASSWORD_ERROR,
    SET_STATUS_FETCHING,
    SET_TAG_FETCHING,
    SET_TAG_REMOVE_FETCHING,
    SET_PASSWORD_FETCHING,
    TOGGLE_MOBILE_NAV,
    SET_COMMUNITY_QUERY,
    GET_STUDENTS_DATA,
    STUDENTS_DATA_SUCCESS,
    STUDENTS_DATA_ERROR,
    GET_EDUCATORS_DATA,
    EDUCATORS_DATA_SUCCESS,
    EDUCATORS_DATA_ERROR,
    GET_MARKERS_DATA,
    CLEAR_MARKERS_DATA,
    MARKERS_DATA_SUCCESS,
    MARKERS_DATA_ERROR,
    GET_SEARCH_FILTER_DATA,
    SEARCH_FILTER_DATA_SUCCESS,
    SEARCH_FILTER_DATA_ERROR,
    UNMOUNT_DISCOVER,
    SET_DISCOVER_PREVIEW
} from '../types';


export default (state, action) => {
    
    switch (action.type) {
        case TOGGLE_MOBILE_NAV:
            return {
                ...state,
                mobNavState: action.payload
            };
        case TOGGLE_LOGIN_MODAL:
            return {
                ...state,
                showLogin: action.payload,
                error: null
            };
        case TOGGLE_REGISTER_MODAL:
            return {
                ...state,
                showRegister: action.payload,
                error: null
            };
        case TOGGLE_ATTEST:
            return {
                ...state,
                toggleAttest: action.payload,
                error: null
            };
        case TOGGLE_REG_SUCCESS_MSG:
            return {
                ...state,
                showRegSuccessMsg: action.payload
            };
        case NAV_REDIRECT:
            return {
                ...state,
                doRedirect: action.payload.doRedirect,
                redirectTo: action.payload.redirectTo
            };
        case SET_FETCHING:
            return {
                ...state,
                isFetching: true,
                error: null
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLogout: false,
                token: action.payload.code,
                error: null
            };
        case USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
                isFetching: false,
                showLogin: false,
                error: null
            };
        case GUEST_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                error: null
            };
        case GUEST_USER_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case RESET_PWD_SUCCESS:
            return { 
                ...state,
                forgotPwdMsg: action.payload.code,
                isFetching: false,
            };
        case RESET_PWD_ERROR:
            return { 
                ...state,
                isFetching: false,
            };
        case RESET_FORGOT_PWD_MSG:
            return {
                ...state,
                forgotPwdMsg: null
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                // user: action.payload,
                // isAuthenticated: true,
                isFetching: false,
                // showRegister: false,
                showRegSuccessMsg: true,
                error: null
            };
        case REGISTER_ERROR:
            return {
                ...state,
                showRegSuccessMsg: false,
                toggleAttest: false,
                isFetching: false,
                error: action.payload
            };
        case LOGIN_ERROR:
        case USER_ERROR:
        case LOGOUT:
            return {
                ...state,
                isLogout: true,
                token: null,
                isAuthenticated: false,
                isFetching: false,
                user: null,
                error: action.payload
            };
        case FORUM_DATA_SUCCESS:
            return {
                ...state,
                forumTotalPosts: action.payload.totalPosts,
                forumTotalStudents: action.payload.totalStudents,
                forumOnlineStudents: action.payload.onlineStudents,
                error: null
            };
        case FORUM_DATA_ERROR:
            return {
                ...state,
                forumTotalPosts: null,
                forumTotalStudents: null,
                forumOnlineStudents: null,
                error: action.payload
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isStatusFetching: false,
                isTagFetching: false,
                isTagRemoveFetching: false,
                isPasswordFetching: false,
                error: null
            };
        case UPDATE_EMAIL_ERROR:
            return {
                ...state,
                isStatusFetching: false,
                isTagFetching: false,
                isTagRemoveFetching: false,
                isPasswordFetching: false,
                errorEmail: action.payload
            };
        case UPDATE_STATUS_ERROR:
            return {
                ...state,
                isStatusFetching: false,
                isTagFetching: false,
                isTagRemoveFetching: false,
                isPasswordFetching: false,
                errorStatus: action.payload
            };
        case UPDATE_TAG_ERROR:
            return {
                ...state,
                isStatusFetching: false,
                isTagFetching: false,
                isTagRemoveFetching: false,
                isPasswordFetching: false,
                errorTag: action.payload
            };
        case UPDATE_CHKBOX_ERROR:
            return {
                ...state,
                isStatusFetching: false,
                isTagFetching: false,
                isTagRemoveFetching: false,
                isPasswordFetching: false,
                errorChkbox: action.payload
            };
        case UPDATE_PASSWORD_ERROR:
            return {
                ...state,
                isStatusFetching: false,
                isTagFetching: false,
                isTagRemoveFetching: false,
                isPasswordFetching: false,
                errorPassword: action.payload
            };
        case SET_STATUS_FETCHING:
            return {
                ...state,
                isStatusFetching: true
            };
        case SET_TAG_FETCHING:
            return {
                ...state,
                isTagFetching: true
            };
        case SET_TAG_REMOVE_FETCHING:
            return {
                ...state,
                isTagRemoveFetching: true
            };
        case SET_PASSWORD_FETCHING:
            return {
                ...state,
                isPasswordFetching: true
            };
        case SET_COMMUNITY_QUERY:
            return {
                ...state,
                communityQuery: action.payload
            };
        case GET_STUDENTS_DATA:
            return {
                ...state,
                errorStudents: null
            };
        case STUDENTS_DATA_SUCCESS:
            return {
                ...state,
                studentsData: action.payload,
                errorStudents: null
            };
        case STUDENTS_DATA_ERROR:
            return {
                ...state,
                errorStudents: action.payload
            };
        case GET_EDUCATORS_DATA:
            return {
                ...state,
                errorEducators: null
            };
        case EDUCATORS_DATA_SUCCESS:
            return {
                ...state,
                educatorsData: action.payload,
                errorEducators: null
            };
        case EDUCATORS_DATA_ERROR:
            return {
                ...state,
                errorEducators: action.payload
            };  
        case GET_MARKERS_DATA:
            return {
                ...state,
                errorMarkers: null
            };
        case CLEAR_MARKERS_DATA:
            return {
                ...state,
                markersData: null
            };
        case MARKERS_DATA_SUCCESS:
            return {
                ...state,
                markersData: action.payload,
                errorMarkers: null
            };
        case MARKERS_DATA_ERROR:
            return {
                ...state,
                errorMarkers: action.payload
            };
        case GET_SEARCH_FILTER_DATA:
            return {
                ...state,
                error: null
            };
        case SEARCH_FILTER_DATA_SUCCESS:
            return {
                ...state,
                searchFilterData: action.payload,
                error: null
            };
        case SEARCH_FILTER_DATA_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case UNMOUNT_DISCOVER:
            return {
                ...state,
                unmountDiscover: action.payload
            };
        case SET_DISCOVER_PREVIEW:
            return {
                ...state,
                discoverPreview: action.payload
            };
        default:
            return state;
    }
    
}
    