import React, { useReducer, useEffect } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';

import { webEndPoint, communityEndPoint, guestToken } from '../../components/hooks/envVariables';
import UserContext from './userContext';
import UserReducer from './userReducer';
import {
    TOGGLE_LOGIN_MODAL,
    TOGGLE_REGISTER_MODAL,
    TOGGLE_ATTEST,
    TOGGLE_REG_SUCCESS_MSG,
    SET_FETCHING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    USER_SUCCESS,
    USER_ERROR,
    GUEST_USER_SUCCESS,
    GUEST_USER_ERROR,
    NAV_REDIRECT,
    RESET_PWD_SUCCESS,
    RESET_PWD_ERROR,
    RESET_FORGOT_PWD_MSG,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    FORUM_DATA_SUCCESS,
    FORUM_DATA_ERROR,
    UPDATE_USER_SUCCESS,
    UPDATE_EMAIL_ERROR,  
    UPDATE_STATUS_ERROR,
    UPDATE_TAG_ERROR,
    UPDATE_CHKBOX_ERROR,
    UPDATE_PASSWORD_ERROR,
    SET_STATUS_FETCHING,
    SET_TAG_FETCHING,
    SET_TAG_REMOVE_FETCHING,
    SET_PASSWORD_FETCHING,
    TOGGLE_MOBILE_NAV,
    SET_COMMUNITY_QUERY,
    GET_STUDENTS_DATA,
    STUDENTS_DATA_SUCCESS,
    STUDENTS_DATA_ERROR,
    GET_EDUCATORS_DATA,
    EDUCATORS_DATA_SUCCESS,
    EDUCATORS_DATA_ERROR,
    GET_MARKERS_DATA,
    CLEAR_MARKERS_DATA,
    MARKERS_DATA_SUCCESS,
    MARKERS_DATA_ERROR,
    SEARCH_FILTER_DATA_SUCCESS,
    SEARCH_FILTER_DATA_ERROR,
    UNMOUNT_DISCOVER,
    SET_DISCOVER_PREVIEW
} from '../types';


const UserState = props => {

    // Define initial app state.
    const initialState = {
        mobNavState         : false,
        showLogin           : false,
        showRegister        : false,
        showRegSuccessMsg   : false,
        toggleAttest        : false,
        token               : null,
        isAuthenticated     : null,
        isFetching          : false,
        isLogout            : false,
        user                : null,
        error               : null,
        doRedirect          : false,
        redirectTo          : null,
        forgotPwdMsg        : null,
        forumTotalPosts     : null,
        forumTotalStudents  : null,
        forumOnlineStudents : null,
        errorEmail          : null,
        errorStatus         : null,
        errorTag            : null,
        errorChkbox         : null,
        errorPassword       : null,
        isStatusFetching    : false,
        isTagFetching       : false,
        isTagRemoveFetching : false,
        isPasswordFetching  : false,
        communityQuery      : null,
        studentsData        : null,
        educatorsData       : null,
        errorStudents       : null,
        errorEducators      : null,
        markersData         : null,
        errorMarkers        : null,
        searchFilterData    : null,
        unmountDiscover     : false,
        discoverPreview     : false
    }
    
    // Initialize reducer hook.
    const [state, dispatch] = useReducer(UserReducer, initialState);
    
    // Initialize cookies context.
    const cookies = new Cookies();

    

    
    /*
     * App methods.
     */
    
    // Set mobile nav state (hide/show).
    const toggleMobileNav = state => {
        
        dispatch({ 
            type: TOGGLE_MOBILE_NAV,  
            payload: state
        });
        
    }
    
    // Set Login modal state.
    const toggleLoginModal = state => {
        
        dispatch({ 
            type: TOGGLE_LOGIN_MODAL,  
            payload: state
        });
        
    }

    // Set Register modal state.
    const toggleRegisterModal = state => {
        
        dispatch({ 
            type: TOGGLE_REGISTER_MODAL,  
            payload: state
        });
        
    }
    
    // Toggle Attest modal.
    const toggleAttestModal = state => {
        
        dispatch({ 
            type: TOGGLE_ATTEST,  
            payload: state
        });
        
    }
    
    const toggleRegSuccessMsgModal = state => {
        
        dispatch({ 
            type: TOGGLE_REG_SUCCESS_MSG,  
            payload: state
        });
        
    }
    
    // Set fetching to show spinner on submit buttons.
    const setFetching = (fetchingType) => dispatch({ type: fetchingType });
    
    // Return the fetching type used on the settings page.
    const getFetchingType = type => {
        
        switch (type) {
            case 'email':
                return null;
            case 'status':
                return SET_STATUS_FETCHING;
            case 'tag':
                return SET_TAG_FETCHING;
            case 'tagRemove':
                return SET_TAG_REMOVE_FETCHING;
            case 'chkbox':
                return null;
            case 'password':
                return SET_PASSWORD_FETCHING;
            default:
                return null;
        }
        
    }
    
    // Return the error type used on the settings page.
    const getErrorType = type => {
        
        switch (type) {
            case 'email':
                return UPDATE_EMAIL_ERROR;
            case 'status':
                return UPDATE_STATUS_ERROR;
            case 'tag':
                return UPDATE_TAG_ERROR;
            case 'tagRemove':
                return UPDATE_TAG_ERROR;
            case 'chkbox':
                return UPDATE_CHKBOX_ERROR;
            case 'password':
                return UPDATE_PASSWORD_ERROR;
            default:
                return null;
        }
        
    }
    
    // Set query string to be appended to the community url.
    const setCommunityQuery = query => {
        
        dispatch({ 
            type: SET_COMMUNITY_QUERY,  
            payload: query
        });
        
    }
    
    // Clear markers data.
    const clearMarkersData = () => {
        
        dispatch({ type: CLEAR_MARKERS_DATA });
        
    }
    
    // Reference if Discover Page is unmounted, and 
    // let marker fetch know, else an error is thrown.
    const nowUnmountDiscover = state => {
        
        dispatch({ 
            type: UNMOUNT_DISCOVER,
            payload: state
        });
        
    }
    
    // Set state that DISCOVER is in PREVIEW mode.
    const setDiscoverPreview = state => {
        
        dispatch({ 
            type: SET_DISCOVER_PREVIEW,
            payload: state
        });
        
    }

    
    
    
    /*
     * Component methods.
     */
    
    // API: Call the User.
    const callApiUser = async token => {
        
        try {
            
            const resObj = await axios.get(`${webEndPoint}/api/user?id=${token}`);
        
            // On success.
            if ( resObj.data.errorCode === 0 ) {
            
                dispatch({
                    type: USER_SUCCESS,
                    payload: resObj.data.payload
                });
            
            }
            
            // On Error.
            // Error msg is in 'data.code'
            else {
                
                dispatch({
                    type: USER_ERROR,
                    payload: resObj.data.errorDescription
                });
                
            }
            
        }
        catch (error) {
            
            console.log('error', error);
            dispatch({
                type: USER_ERROR,
                payload: 'Network error' // error.statusText ???
            });
            
        }
        
    }
    
    // API: Call the Guest User.
    const callApiGuestUser = async token => {
        
        try {
            
            const resObj = await axios.get(`${webEndPoint}/api/user?id=${token}`);
            
            // On success.
            if ( resObj.data.errorCode === 0 ) {
            
                dispatch({
                    type: GUEST_USER_SUCCESS,
                    payload: resObj.data.payload
                });
            
            }
            
            // On Error.
            // Error msg is in 'data.code'
            else {
                
                dispatch({
                    type: GUEST_USER_ERROR,
                    payload: resObj.data.errorDescription
                });
                
            }
            
        }
        catch (error) {
            
            console.log('error', error);
            dispatch({
                type: GUEST_USER_ERROR,
                payload: 'Network error' // error.statusText ???
            });
            
        }
        
    }
    
    // API: Login user.
    const login = async reqObj => {
        
        setFetching(SET_FETCHING);
        
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        try {
            
            const resObj = await axios.post(`${webEndPoint}/api/userlogin`, reqObj, config);
            
            // On success.
            if ( resObj.data.errorCode === 0 ) {

                // const dnm       = window.location.hostname;
                // const tld       = dnm === 'localhost' ? 'localhost' : dnm.match(/[^.\s/]+\.([a-z]{3,}|[a-z]{2}.[a-z]{2})$/)[0];
                // const dte       = new Date();
                // const exp       = dte.setFullYear(dte.getFullYear + 1);
                // const max       = 30 * 24 * 60 * 60;
                
                //if ( reqObj.rememberMe ) cookies.set('learningcommunity', resObj.data.code, { path: '/', domain: tld, expires: exp, maxAge: max });
                
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: resObj.data
                });
                
                callApiUser(resObj.data.code);
            
            }
            
            // On Error.
            // Error msg is in data.code
            else {
                
                dispatch({
                    type: LOGIN_ERROR,
                    payload: resObj.data.code
                });
                
            }
            
        }
        catch (error) {
            
            console.log('error', error);
            dispatch({
                type: LOGIN_ERROR,
                payload: 'Network error' // error.statusText ???
            });
            
        }
 
    }
    
    // Logout user.
    const logout = () => {
        
        //cookies.remove('learningcommunity', { path: '/' });

        dispatch({ type: LOGOUT });
        
    }
    
    // On app load if we have a cookie token then we can
    // send LOGIN_SUCCESS & test that token by calling 'api/user'.
    const authenticate = token => {
        
        dispatch({
            type: LOGIN_SUCCESS,
            payload: token
        });
        
        callApiUser(token);
        
    }
    
    // After a protected navlink login the user should be redirected
    // to their original page.
    const navRedirect = state => {
        
        dispatch({
            type: NAV_REDIRECT,
            payload: state
        });
        
    }
    
    // API: Request a new password.
    const resetPassword = async reqObj => {
        
        setFetching(SET_FETCHING);
        
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        try {
            
            const resObj = await axios.post(`${webEndPoint}/api/resetpassword`, reqObj, config);
            
            console.log('resObj.data.code', resObj.data.code);
            
            // On success.
            if ( resObj.data.errorCode === 0 ) {
                
                dispatch({
                    type: RESET_PWD_SUCCESS,
                    payload: resObj.data
                });
            
            }
            
            // On Error.
            // Error msg is in data.code
            else {
                
                dispatch({
                    type: RESET_PWD_ERROR,
                    payload: resObj.data.code
                });
                
            }
            
        }
        catch (error) {
            
            console.log('error', error);
            dispatch({
                type: RESET_PWD_ERROR,
                payload: 'Network error' // error.statusText ???
            });
            
        }
        
    }
    
    const resetForgotPwdMsg = () => dispatch({ type: RESET_FORGOT_PWD_MSG });
    
    // API: Register a new user.
    const register = async reqObj => {
        
        setFetching(SET_FETCHING);
        
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };       

        try {
            
            const resObj = await axios.post(`${webEndPoint}/api/user`, reqObj, config);
            
            // On success.
            if ( resObj.data.errorCode === 0 ) {
                
                dispatch({
                    type: REGISTER_SUCCESS,
                    payload: resObj.data.payload
                });
            
            }
            
            // On Error.
            // Error msg is in data.code
            else {
                
                dispatch({
                    type: REGISTER_ERROR,
                    payload: resObj.data.errorDescription
                });
                
            }
            
        }
        catch (error) {
            
            console.log('error', error);
            dispatch({
                type: REGISTER_ERROR,
                payload: 'Network error' // error.statusText ???
            });
            
        }
        
    }
    
    // API: Get Forum data.
    const fetchForumData = async () => {
        
        try {
            
            const resObj = await axios.get(`${communityEndPoint}/api/Metrics.php`);

            // On success.
            if ( resObj.status === 200 ) {
            
                const posts = resObj.data.postCounts.filter(item => item.type === 'Q' )[0];
        
                dispatch({
                    type: FORUM_DATA_SUCCESS,
                    payload: {
                        totalPosts      : posts.count.toLocaleString(),
                        totalStudents   : resObj.data.usersCount.toLocaleString(),
                        onlineStudents  : resObj.data.sessions.toLocaleString()
                    }
                });
            
            }
            
            // On Error.
            // Error msg is in 'data.code'
            else {
                
                dispatch({
                    type: FORUM_DATA_ERROR
                });
                
            }
            
        }
        catch (error) {
            
            console.log('error', error);
            dispatch({
                type: FORUM_DATA_ERROR
            });
            
        }
        
    }
    
    // API: Put updated user data on the server.
    const updateUserData = async (reqObj, updateType) => {
        
        const fetchingType  = getFetchingType(updateType);
        const errorType     = getErrorType(updateType);
        
        if ( fetchingType !== null ) setFetching(fetchingType);
        
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        
        // Grab logged in user cookie token.
        let token   = cookies.get('learningcommunity');
        token       = encodeURIComponent(token);

        try {
            
            const resObj = await axios.put(`${webEndPoint}/api/user?id=${token}`, reqObj, config);
            
            // On success.
            if ( resObj.data.errorCode === 0 ) {
                
                dispatch({
                    type: UPDATE_USER_SUCCESS,
                    payload: resObj.data.payload
                });
            
            }
            
            // On Error.
            // Error msg is in data.code
            else {
                
                dispatch({
                    type: errorType,
                    payload: resObj.data.errorDescription
                });
                
            }
            
        }
        catch (error) {
            
            console.log('error', error);
            dispatch({
                type: errorType,
                payload: 'Network error' // error.statusText ???
            });
            
        }
        
    }
    
    // API: Fetch STUDENTS or EDUCATORS data from server.
    const getDiscoverData = async queryObj => {
        
        let token = '';
    
        // Grab logged in user cookie token.
        if ( !state.discoverPreview ) {
            
            token   = cookies.get('learningcommunity');
            token   = encodeURIComponent(token);
            
        }
        // Else use guest token.
        else token  = guestToken; 
        
        // Deconstruct query string parameters.
        const params = {
            Id: token,
            ...queryObj
        }
        
        // Reset data error props.
        dispatch({
            type: params.searchType === 1 ? GET_STUDENTS_DATA : GET_EDUCATORS_DATA
        });
        
        // Fetch data.
        try {
            
            const resObj = await axios.get(`${webEndPoint}/api/learning`, { params });
            
            // If Discover Component has been unmounted return null
            // else we will get an error thrown.
            // if ( state.unmountDiscover ) {
                
            //     nowUnmountDiscover(false);
            //     return null;
                
            // }

            // On success.
            if ( resObj.data.errorCode === 0 ) {

                dispatch({
                    type: params.searchType === 1 ? STUDENTS_DATA_SUCCESS : EDUCATORS_DATA_SUCCESS,
                    payload: resObj.data.payload.list
                });
            
            }
            
            // On Error.
            // Error msg is in 'data.errorDescription'
            else {
                
                dispatch({
                    type: params.searchType === 1 ? STUDENTS_DATA_ERROR : EDUCATORS_DATA_ERROR,
                    payload: resObj.data.errorDescription
                });
                
            }
            
        }
        catch (error) {
            
            console.log('error', error);
            dispatch({
                type: params.searchType === 1 ? STUDENTS_DATA_ERROR : EDUCATORS_DATA_ERROR,
                payload: 'Network error' // error.statusText ???
            });
            
        }
        
    }
    
    // API: Fetch MARKERS data from the server.
    const getMarkersData = async queryObj => {
        
        let token = '';
    
        // Grab logged in user cookie token.
        if ( !state.discoverPreview ) {
            
            token   = cookies.get('learningcommunity');
            token   = encodeURIComponent(token);
            
        }
        // Else use guest token.
        else token  = guestToken; 
        
        // Deconstruct query string parameters.
        const params = {
            Id: token,
            ...queryObj
        }
        
        // Reset data error props.
        dispatch({
            type: GET_MARKERS_DATA
        });
        
        // Fetch data.
        try {
            
            const resObj = await axios.get(`${webEndPoint}/api/learning`, { params });
            
            // If Discover Component has been unmounted return null
            // else we will get an error thrown.
            // if ( state.unmountDiscover ) {
                
            //     nowUnmountDiscover(false);
            //     return null;
                
            // }
        
            // On success.
            if ( resObj.data.errorCode === 0 ) {
            
                dispatch({
                    type: MARKERS_DATA_SUCCESS,
                    payload: resObj.data.payload.list
                });
            
            }
            
            // On Error.
            // Error msg is in 'data.errorDescription'
            else {
                
                dispatch({
                    type: MARKERS_DATA_ERROR,
                    payload: resObj.data.errorDescription
                });
                
            }
            
        }
        catch (error) {
            
            console.log('error', error);
            dispatch({
                type: MARKERS_DATA_ERROR,
                payload: 'Network error' // error.statusText ???
            });
            
        }
        
    }
    
    // API: Fetch Search type data, which is students & list of educators.
    const getSearchFilterData = async () => {
        
        let token = '';
    
        // Grab logged in user cookie token.
        if ( !state.discoverPreview ) {
            
            token   = cookies.get('learningcommunity');
            token   = encodeURIComponent(token);
            
        }
        // Else use guest token.
        else token  = guestToken; 
        
        try {
            
            const resObj = await axios.get(`${webEndPoint}/api/searchfilter?fromId=${token}`);
            
            // If Discover Component has been unmounted return null
            // else we will get an error thrown.
            // if ( state.unmountDiscover ) {
                
            //     nowUnmountDiscover(false);
            //     return null;
                
            // }
        
            // On success.
            if ( resObj.data.errorCode === 0 ) {
            
                dispatch({
                    type: SEARCH_FILTER_DATA_SUCCESS,
                    payload: resObj.data.payload.types
                });
            
            }
            
            // On Error.
            // Error msg is in 'data.errorDescription'
            else {
                
                dispatch({
                    type: SEARCH_FILTER_DATA_ERROR,
                    payload: resObj.data.errorDescription
                });
                
            }
            
        }
        catch (error) {
            
            console.log('error', error);
            dispatch({
                type: SEARCH_FILTER_DATA_ERROR,
                payload: 'Network error' // error.statusText ???
            });
            
        }
        
    }
    
    
    
    
    
    
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        // Check to see if we have a token cookie.
        const token             = cookies.get('learningcommunity');
        const hasValidCookie    = typeof token !== 'undefined' && token !== guestToken ? true : false;
        
        // If we have a token cookie then validate it
        // by calling 'api/user' & fetching user data.
        if ( hasValidCookie ) {
            
            authenticate(encodeURIComponent(token));
            
        }
        else {
            
            dispatch({
                type: LOGIN_ERROR,
                payload: 'User not logged in'
            });
            
        }
        
        // The eslint comment below removes missing dependency warning from console.
        // eslint-disable-next-line
    }, []);
    
    
    
    /*
     * Return context state & methods.
     */
    
    return <UserContext.Provider value={{
        mobNavState             : state.mobNavState,
        showLogin               : state.showLogin,
        showRegister            : state.showRegister,
        showRegSuccessMsg       : state.showRegSuccessMsg,
        toggleAttest            : state.toggleAttest,
        token                   : state.token,
        isAuthenticated         : state.isAuthenticated,
        isFetching              : state.isFetching,
        isLogout                : state.isLogout,
        user                    : state.user,
        error                   : state.error,
        doRedirect              : state.doRedirect,
        redirectTo              : state.redirectTo,
        forgotPwdMsg            : state.forgotPwdMsg,
        forumTotalPosts         : state.forumTotalPosts,
        forumTotalStudents      : state.forumTotalStudents,
        forumOnlineStudents     : state.forumOnlineStudents,
        errorEmail              : state.errorEmail,
        errorStatus             : state.errorStatus,
        errorTag                : state.errorTag,
        errorChkbox             : state.errorChkbox,
        errorPassword           : state.errorPassword,
        isStatusFetching        : state.isStatusFetching,
        isTagFetching           : state.isTagFetching,
        isTagRemoveFetching     : state.isTagRemoveFetching,
        isPasswordFetching      : state.isPasswordFetching,
        communityQuery          : state.communityQuery,
        studentsData            : state.studentsData,
        educatorsData           : state.educatorsData,
        markersData             : state.markersData,
        errorStudents           : state.errorStudents,
        errorEducators          : state.errorEducators,
        errorMarkers            : state.errorMarkers,
        searchFilterData        : state.searchFilterData,
        unmountDiscover         : state.unmountDiscover,
        toggleMobileNav,
        authenticate,
        toggleLoginModal,
        toggleRegisterModal,
        toggleAttestModal,
        toggleRegSuccessMsgModal,
        login,
        logout,
        callApiUser,
        callApiGuestUser,
        resetPassword,
        navRedirect,
        resetForgotPwdMsg,
        register,
        fetchForumData,
        updateUserData,
        setCommunityQuery,
        getDiscoverData,
        getMarkersData,
        clearMarkersData,
        getSearchFilterData,
        nowUnmountDiscover,
        setDiscoverPreview
    }}>
        {props.children}
    </UserContext.Provider>
    
}

export default UserState;