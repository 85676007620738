import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { guestToken } from '../hooks/envVariables';
import Footer from '../layout/Footer';
import UserContext from '../../context/user/userContext';


const Home = () => {
    
    // Define components state hooks.
    const [isDataReady, setIsDataReady] = useState(false);
    const [firstLoad, setFirstLoad]     = useState(false);
    
    // Reference context apis.
    const userContext                   = useContext(UserContext);
    
    // Reference hooks.
    const history                       = useHistory();
    const location                      = useLocation();
    
    // Destructure contexts.
    const { toggleMobileNav, 
            isAuthenticated, 
            toggleLoginModal, 
            toggleRegisterModal, 
            forumTotalPosts, 
            forumTotalStudents, 
            forumOnlineStudents, 
            fetchForumData, 
            user, 
            isLogout,
            navRedirect, 
            setCommunityQuery }         = userContext;
    

    /*
     * Component methods.
     */
    
    const onClickLogin = () => {
        
        // Open a login modal through the context api.
        toggleLoginModal(true);
        
    }
    
    const onClickRegister = () => {
        
        // Open a login modal through the context api.
        toggleRegisterModal(true);
        
    }
    
    const onClickNavigateTo = path => {
        
        // Reset Community tag.
        setCommunityQuery(null);
        
        // Navigate to the path using history push.
        history.push('/' + path);
        
    }
    
    const onClickAppPage = event => {
        
        // Hide the mobile nav, even if not open.
        toggleMobileNav(false);
        
    }
    
    // Before handling navigation check if isAuthenticated.
    const onClickNavigateProtected = (path) => {
        
        // Reset Community tag.
        setCommunityQuery(null);
        
        // If user isAuthenticated navigate to page.
        if ( isAuthenticated ) {
            
            history.push('/' + path);
        
            toggleMobileNav(false);
            
        }
        
        // Else open Login modal, on login navigate to page.
        else {
            
            navRedirect({ doRedirect: true, redirectTo: path });
            toggleLoginModal(true);
            
        }
        
        toggleMobileNav(false); 
        
    }
    
    // Navigate to the Community with a defined tag.
    const onClickCommunityWithTag = id => {
        
        // Get tags acronym. 
        const tag = getTagFromId(id);
        
        // Set tag.
        setCommunityQuery('/tag?et=' + tag);
        
        // Navigate to the Community/Forum page using history push.
        history.push('/community/forum');
        
    }
    
    // Return the tags acronym using the 'tagLookupUNID'.
    const getTagFromId = id => {
        
        const item = user.tags.find(item => item.UNID === id);
        
        return item.tag;
        
    }
    
    
    
    
    /*
     * Component hooks.
     */
    
    useEffect(() => {
      
        // Fetch forum data.
        fetchForumData();
        
        // Show the home page.
        setIsDataReady(true);

    // The eslint comment below removes missing dependency warning from console.
    // eslint-disable-next-line
    }, []);
    
    useEffect(() => {

        // If url is a DEEPLINK then direct user to either 
        // Community home or an exam channel in the forum
        if ( location.search.includes('?et=') ) {
            
            // Check we have a valid 'learningcommunity' cookie (not a guest cookie).
            const cookies           = new Cookies();
            const token             = cookies.get('learningcommunity');
            const hasValidCookie    = typeof token !== 'undefined' && token !== guestToken ? true : false;
            
            // If we have a valid cookie we
            // can go to the protected page.
            if ( hasValidCookie ) {
                
                // When we have User data we can 
                // check if our url exam tag is valid.
                if ( user !== null ) {
                    
                    const tagsArr   = [];
                    const examTag   = location.search.substring(4, location.search.length);
                    
                    // Array holding all valid tags.
                    user.tags.forEach(item => tagsArr.push(item.tag));
                    
                    // Tag is VALID, set channel path & go to forum.
                    if ( tagsArr.includes(examTag) ) {
                        
                        setCommunityQuery('/tag?et=' + examTag);
                        history.push('/community/forum');
                        
                    }
                    
                    // Tag is INVALID, clear path & go to Community home.
                    else navRedirect({ doRedirect: true, redirectTo: 'community' });
                    
                }
 
            }
            
            // We don't have a valid cookie so redirect 
            // user to Community home & allow them to log in.
            else {
                        
                navRedirect({ doRedirect: true, redirectTo: 'community' });
                toggleLoginModal(true);
 
            }
   
        }

    }, [user]);
    
    
    /*
     * Component view.
     */
    return (
        <>
        { isDataReady ?
            <div className="pages" onClick={event => onClickAppPage(event)}>
                <div className="hero-img home">
                    <div className="fixed-width flex-row-dbl z-top">
                        <div className="hero-title">
                            Learning Community
                        </div>
                        <div className="hero-box"></div>
                    </div>
                </div>
                <div className="fixed-width flex-fill-space">
                    
                    <div className="pg-intro">
                        { isAuthenticated ? 
                        <>
                            <p className="txt-type-1">Welcome to the learning community {user.FN}</p>
                            <p className="txt-type-1">
                                Keep your <button className="btn-style-link txt-red" 
                                                onClick={() => onClickNavigateTo('settings')}>profile settings</button> up to date for more personalised content
                            </p>
                        </>
                        :
                        <>
                            {/* <p className={'txt-type-1' + ( forumTotalPosts !== null && forumTotalStudents !== null ? '' : ' hide' )}>{forumTotalPosts} posts by {forumTotalStudents} students</p> */}
                            <p className="txt-type-1">
                                <button className="btn-style-link txt-red" 
                                        onClick={onClickLogin}>Login</button> or <button className="btn-style-link txt-red" 
                                                                                        onClick={onClickRegister}>Register</button> to get full access to the ACCA Learning Community
                            </p>
                            <p className="info-msg-warning">
                                We are aware of a problem that is impacting students trying to register for the ACCA Learning Community and we're working to resolve this as quickly as possible. We apologise for the disruption and will update you as soon as we can.
                            </p>
                        </>
                        }
                    </div>
                    
                    <div className="pg-summary community">
                        <div>
                            <button className="icon"
                                    onClick={() => onClickNavigateProtected('community')}>
                                <i className="icon-community"></i>
                            </button>
                        </div>
                        <div>
                            <p>
                                <button className="txt-type-1 align-left pad-rgt-15" 
                                        onClick={() => onClickNavigateProtected('community')}>Ask the community</button>
                                <span className={'txt-type-2 wrap-500' + ( forumOnlineStudents !== null ? '' : ' hide' )}>{forumOnlineStudents} students online now</span>
                            </p>
                            <p>Share tips, ideas and questions with an international community of students.</p>
                            { ( isAuthenticated && user.usertags.length > 0 ) &&
                            <>
                                <div className="box-list-wrapper row">
                                    <ul className="box-list row">
                                        {user.usertags.map((option, idx) => {
                                            return (
                                                <li key={idx}>
                                                    <button onClick={() => onClickCommunityWithTag(option.tagLookupUNID)}>#{getTagFromId(option.tagLookupUNID)}</button>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </>
                            }
                            { isAuthenticated &&
                            <button className="btn-tertiary arrow-rgt" onClick={() => onClickNavigateTo('community')}>View channels</button>
                            }
                        </div>
                    </div>
                    
                    <div className="pg-summary discover">
                        <div>
                            <button className="icon"
                                    onClick={() => onClickNavigateProtected('discover')}>
                                <i className="icon-discover"></i>
                            </button>
                        </div>
                        <div>
                            <p>
                                <button className="txt-type-1 align-left" 
                                        onClick={() => onClickNavigateProtected('discover')}>Discover the community</button>
                            </p>
                            <p>Discover students and Approved Learning Partners near you.</p>
                            { isAuthenticated &&
                            <>
                                <div className="box-list-wrapper">
                                    <ul className="box-list">
                                        <li className={ forumTotalStudents !== null ? '' : ' hide' }>
                                            <button onClick={() => onClickNavigateTo('discover')}>{forumTotalStudents} Students</button>
                                        </li>
                                        <li>
                                            <button onClick={() => onClickNavigateTo('discover')}>Approved Learning Providers</button>
                                        </li>
                                    </ul>
                                </div>
                                <button className="btn-tertiary arrow-rgt" onClick={() => onClickNavigateTo('discover')}>View now</button>
                            </>
                            }                               
                        </div>
                    </div>
                    
                </div>
                <Footer />
            </div>
        :
            <div></div>
        }
        </>
    );
    
}

export default Home;
